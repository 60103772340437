import Vue from 'vue';
import socketio from 'socket.io-client';
// import socketio from 'socket.io';
import VueSocketIO from 'vue-socket.io';

// export const SocketInstance = socketio('http://localhost:3030');
// Vue.use(VueSocketIO, SocketInstance)
const port = localStorage.getItem('port') || 3030;
const io = new VueSocketIO({
  // debug: true,
  connection: window.webpackHotUpdate ? `http://localhost:${port}` : '/',
});
Vue.use(io);
Vue.prototype.$io = io;

// import io from 'socket.io-client';
// const socket = io('http://localhost:3030');
//
// const SocketPlugin = {
//   install(vue) {
//     vue.mixin({});
//     vue.prototype.$send = (channel, ...msg) => {
//       socket.emit(channel, ...msg);
//     };
//     vue.prototype.$socket = socket;
//   },
// };
//
// Vue.use(SocketPlugin);
