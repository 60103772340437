<template>
  <b-card>
    <slot name="header"></slot>

    <b-row class="form-group">
      <b-col cols="10" slot="header" class="col-form-label">
        <div class="d-inline" v-html="caption"></div>
        <b-button v-if="hasMore" size="sm" class="ml-2" variant="light" @click="getMore" :disabled="getMoreBusy">
          More
          <b-spinner class="ml-1" small v-if="getMoreBusy"></b-spinner>
        </b-button>
      </b-col>
      <b-col cols="2" class="clearfix">
        <b-form inline class="pull-right">
          <b-form-select :options="[10,20,30,40,50,100,200,500,1000]" v-model="_perPage"></b-form-select>
        </b-form>
      </b-col>
    </b-row>

    <b-table :class="$attrs.class" show-empty :dark="dark" :hover="hover" :striped="striped" :bordered="bordered" :small="small" :fixed="fixed" responsive
             :items="items" :fields="fields" :current-page="currentPage" :per-page="_perPage" :busy="isBusy" :sticky-header="false" :empty-html="emptyHtml"
             @row-clicked="rowClicked">
      <template v-slot:head(selected)="data">
        <b-form-checkbox v-model="allSelected" @input="toggleAll"></b-form-checkbox>
      </template>
      <template v-slot:cell(selected)="data">
        <b-form-checkbox :id="_id + '_checkbox'+data.index" v-model="data.item.selected" @input="toggleOne"></b-form-checkbox>
      </template>

      <template v-slot:head(_img40)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(_img60)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(_img40_2)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(_img60_2)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:cell(_img40)="data">
        <span v-if="data.value" :id="_id + 'img40-'+data.index" v-html="data.value"></span>
        <img v-else :id="_id + 'img40-'+data.index" :src="data.item.img" :style="{height: '40px', maxWidth: '40px', ...data.item.css_img}"/>
        <b-popover :target="_id + `img40-${data.index}`" placement="right" triggers="hover focus">
          <img :src="data.item.img" style="max-width:200px"/>
        </b-popover>
      </template>
      <template v-slot:cell(_img60)="data">
        <span v-if="data.value" :id="_id + 'img60-'+data.index" v-html="data.value"></span>
        <img v-else :id="_id + 'img60-'+data.index" :src="data.item.img" :style="{height: '60px', maxWidth: '60px', ...data.item.css_img}"/>
        <b-popover :target="_id + `img60-${data.index}`" placement="right" triggers="hover focus">
          <img :src="data.item.img" style="max-width:200px"/>
        </b-popover>
      </template>
      <template v-slot:cell(_img40_2)="data">
        <span v-if="data.value" :id="_id + 'img40_2-'+data.index" v-html="data.value"></span>
        <img v-else :id="_id + 'img40_2-'+data.index" :src="data.item.img2" :style="{height:'40px', ...data.item.css_img2}"/>
        <b-popover :target="_id + `img40_2-${data.index}`" placement="right" triggers="hover focus">
          <img :src="data.item.img2" style="max-width:200px"/>
        </b-popover>
      </template>
      <template v-slot:cell(_img60_2)="data">
        <span v-if="data.value" :id="_id + 'img60_2-'+data.index" v-html="data.value"></span>
        <img v-else :id="_id + 'img60_2-'+data.index" :src="data.item.img2" :style="{height:'60px', ...data.item.css_img2}"/>
        <b-popover :target="_id + `img60_2-${data.index}`" placement="right" triggers="hover focus">
          <img :src="data.item.img2" style="max-width:200px"/>
        </b-popover>
      </template>
      <template v-slot:cell(_pop)="data">
        <span v-if="data.value" :id="_id + 'img40-'+data.index" v-html="data.value"></span>
        <img v-else :id="_id + 'img40-'+data.index" :src="data.item.img" :style="{height:'40px', ...data.item.css_img}"/>
        <b-popover :target="_id + `img40-${data.index}`" placement="right" triggers="hover focus">
          <img :src="data.item.img" style="max-height:400px"/>
        </b-popover>
      </template>
      <template v-slot:head(pop1)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:cell(pop1)="data">
        <div :style="data.field.style" :id="`${_id}pop1-${data.index}`" v-html="data.value(data.item)"></div>
        <b-popover :target="`${_id}pop1-${data.index}`" placement="right" triggers="hover focus">
          <div v-html="data.item._pop1(data.item)"></div>
        </b-popover>
      </template>

      <template v-slot:head(html1)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(html2)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(html3)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(html4)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(html5)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(html6)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(html7)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(html8)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(html9)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(html10)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(html11)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:cell(html1)="data">
        <div :style="data.field.style" v-html="data.value"></div>
      </template>
      <template v-slot:cell(html2)="data">
        <div :style="data.field.style" v-html="data.value"></div>
      </template>
      <template v-slot:cell(html3)="data">
        <div :style="data.field.style" v-html="data.value"></div>
      </template>
      <template v-slot:cell(html4)="data">
        <div :style="data.field.style" v-html="data.value"></div>
      </template>
      <template v-slot:cell(html5)="data">
        <div :style="data.field.style" v-html="data.value"></div>
      </template>
      <template v-slot:cell(html6)="data">
        <div :style="data.field.style" v-html="data.value"></div>
      </template>
      <template v-slot:cell(html7)="data">
        <div :style="data.field.style" v-html="data.value"></div>
      </template>
      <template v-slot:cell(html8)="data">
        <div :style="data.field.style" v-html="data.value"></div>
      </template>
      <template v-slot:cell(html9)="data">
        <div :style="data.field.style" v-html="data.value"></div>
      </template>
      <template v-slot:cell(html10)="data">
        <div :style="data.field.style" v-html="data.value"></div>
      </template>
      <template v-slot:cell(html11)="data">
        <div :style="data.field.style" v-html="data.value"></div>
      </template>

      <template v-slot:head(lambda1)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(lambda2)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(lambda3)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(lambda4)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:head(lambda5)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:cell(lambda1)="data">
        <div :style="data.field.style" v-html="data.value(data.item)"></div>
      </template>
      <template v-slot:cell(lambda2)="data">
        <div :style="data.field.style" v-html="data.value(data.item)"></div>
      </template>
      <template v-slot:cell(lambda3)="data">
        <div :style="data.field.style" v-html="data.value(data.item)"></div>
      </template>
      <template v-slot:cell(lambda4)="data">
        <div :style="data.field.style" v-html="data.value(data.item)"></div>
      </template>
      <template v-slot:cell(lambda5)="data">
        <div :style="data.field.style" v-html="data.value(data.item)"></div>
      </template>

      <template v-slot:head(inp1)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:cell(inp1)="data">
        <b-input :style="data.field.style" v-model="data.value" @change="inputChanged(data)"></b-input>
      </template>

      <template v-slot:cell(show_details)="row">
        <div :style="row.field.style">
          <b-button size="sm" @click="row.toggleDetails" class="">
            {{ row.detailsShowing ? 'Hide' : 'Details' }}
          </b-button>
        </div>
      </template>

      <template v-slot:cell(show_modal)="row">
        <div :style="row.field.style">
          <b-button size="sm" @click="showModalClicked(row)" class="">
            {{ row.item._modal_btn_label || '상세' }}
          </b-button>
        </div>
      </template>

      <template v-slot:head(_actions)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:cell(_actions)="row">
        <div :style="row.field.style">
          <b-button size="sm" v-for="(btn, idx) in row.field.buttons" :variant="btn.variant" :disabled="btn.disabled" :title="btn.title"
                    v-if="!btn.if || btn.if(row.item)" @click="btnClicked(row, btn.event || idx)" class="mr-1 mb-1">
            {{ btn.label || 'Button' + idx }}
          </b-button>
        </div>
      </template>
      <template v-slot:head(_actions2)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:cell(_actions2)="row">
        <div :style="row.field.style">
          <template v-for="(btn, idx) in row.field.buttons">
            <b-button size="sm" :variant="btn.variant" :disabled="btn.disabled" :title="btn.title" v-if="!btn.if || btn.if(row.item)"
                      @click="btnClicked(row, btn.event || idx)" class="mr-1 mb-1">
              {{ btn.label || 'Button' + idx }}
            </b-button>
            <span v-if="btn.if && !btn.if(row.item)" v-html="btn.else(row.item)"></span>
          </template>
        </div>
      </template>
      <template v-slot:head(_actions3)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:cell(_actions3)="row">
        <div :style="row.field.style">
          <template v-for="(target, idx) in row.field.targets">
            <span :style="target.style" v-if="target.html || row.item._actions3_html" v-html="target.html || row.item._actions3_html" @click="btnClicked(row, target.event || idx)"></span>
            <span :style="target.style" v-else v-html="row.value"></span>
          </template>
        </div>
      </template>
      <template v-slot:head(_actions4)="data">
        <div :style="data.field.style" v-html="data.label"></div>
      </template>
      <template v-slot:cell(_actions4)="row">
        <div :style="row.field.style">
          <template v-for="(btn, idx) in row.field.buttons">
            <b-button size="sm" :variant="btn.variant" v-if="!btn.if || btn.if(row.item)" @click="btnClicked(row, btn.event || idx)" class="mr-1 mb-1" :class="btn.class">
              {{ btn.label || '' }}
            </b-button>
            <span v-if="btn.if && !btn.if(row.item)" v-html="btn.else(row.item)"></span>
          </template>
        </div>
      </template>

      <template v-slot:row-details="row">
        <span v-html="row.item._html"></span>
      </template>

      <template v-slot:table-busy>
        <div class="text-center text-info my-2">
          <b-spinner class="align-middle"></b-spinner>
        </div>
      </template>
    </b-table>
    <b-row>
      <b-col lg="10">
        <div class="clearfix">
          <nav class="pull-left">
            <b-pagination :total-rows="totalRows" :per-page="perPage" :limit="11" v-model="currentPage" prev-text="Prev" next-text="Next" @input="pageInput"/>
          </nav>
          <b-button v-if="hasMore" class="pull-left ml-2" variant="primary" @click="getMore" :disabled="getMoreBusy">
            <b-spinner class="mr-2" small v-if="getMoreBusy"></b-spinner>
            More
          </b-button>
        </div>
      </b-col>
      <b-col class="d-none d-lg-block clearfix" lg="2">
        <b-form inline class="pull-right">
          <b-form-select :options="[10,20,30,40,50,100,200,500,1000]" v-model="_perPage"></b-form-select>
        </b-form>
      </b-col>
    </b-row>

    <slot name="footer"></slot>
  </b-card>
</template>

<script>
export default {
  name: 'c-table',
  inheritAttrs: false,
  props: {
    id: {type: String, default: ''},
    caption: {type: String, default: 'Table'},
    hover: {type: Boolean, default: false},
    striped: {type: Boolean, default: false},
    bordered: {type: Boolean, default: false},
    small: {type: Boolean, default: false},
    fixed: {type: Boolean, default: false},
    tableData: {type: [Array, Function], default: () => []},
    fields: {type: [Array, Object], default: () => []},
    perPage: {type: Number},
    isBusy: {type: Boolean, default: false},
    getMoreBusy: {type: Boolean, default: null},
    hasMore: {type: Boolean, default: false},
    dark: {type: Boolean, default: false},
    emptyHtml: {type: String, default: ''},
  },
  data: () => {
    return {
      _id: null,
      currentPage: 1,
      allSelected: false,
    }
  },
  computed: {
    items: function () {
      const items = this.tableData;
      return Array.isArray(items) ? items : items()
    },
    totalRows: function () {
      return this.getRowCount()
    },
    selectedRows: {
      get() {
        return this.tableData.filter(e => e.selected).length;
      },
    },
    // captions: function () {
    //   return this.fields
    // },
    _perPage: {
      get() {
        return this.perPage;
      },
      set(v) {
        this.$emit('update:perPage', v);
      }
    }
  },
  created() {
    this._id = this.id || 'tbl_' + (Math.random() + '').slice(2, 10);
  },
  mounted() {
    this.allSelected = this.tableData.length && this.tableData.every(e => e.selected);
  },
  methods: {
    getRowCount() {
      return this.items.length
    },
    rowClicked(row) {
      this.$emit('row-clicked', row);
    },
    showModalClicked(row) {
      this.$emit('show-modal-clicked', row);
      // console.log(row);
    },
    btnClicked(row, event) {
      if (event === 'show_details') {
        row.toggleDetails();
      } else {
        this.$emit('btn-clicked', row, event);
      }
    },
    inputChanged(row) {
      this.$emit('input-changed', row);
    },
    pageInput(page) {
      let totalPage = this.items.length / this.perPage;
      if (page >= totalPage - 2) { // 마지막 2 페이지
        this.getMore();
      }
    },
    resetPage() {
      this.currentPage = 1;
    },
    getMore() {
      if (this.hasMore) this.$emit('get-more');
    },
    toggleAll(v) {
      if (v || this.items.length === this.items.filter(e => e.selected).length) this.items.forEach(e => e.selected = v);
      this.$forceUpdate();
    },
    toggleOne(v) {
      if (!this.items.length || this.allSelected === v || this.allSelected === this.items.every(e => e.selected)) return;
      this.allSelected = this.items.every(e => e.selected);
      this.$forceUpdate();
    }
  }
}
</script>
