<template>
  <b-form inline>
    <slot name="input-pre"></slot>
    <date-input v-model="_from" v-bind="{calendar: this.calendar}" @enter="onEnter('from')"></date-input>
    <slot name="tilde">
      &nbsp;∽&nbsp;
    </slot>
    <date-input v-model="_to" v-bind="{calendar: this.calendar}" @enter="onEnter('to')"></date-input>
    <slot name="input-post"></slot>
    <b-button-group v-if="!inputOnly">
      <b-button v-if="all" variant="light" @click="setAllRange()">전기간</b-button>
      <b-button v-if="today" variant="light" @click="setDateRange(undefined, [0, 'day'])">오늘</b-button>
      <b-button v-if="yesterday" variant="light" @click="setDateRange([-1, 'day'], [0, 'day'])">어제</b-button>
      <b-button v-if="twoDays" variant="light" @click="setDateRange([-1, 'day'], [1, 'day'])">어제오늘</b-button>
      <b-dropdown v-if="day > 0" variant="secondary" text="일">
        <b-dropdown-item v-for="e in Array(day).fill(0).map((_, i) => i + 1)" @click="setDateRange(undefined, [-e, 'day'])" :key="e">
          {{e}}일 전
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown v-if="week > 0" variant="secondary" text="주">
        <b-dropdown-item v-for="e in Array(week).fill(0).map((_, i) => i + 1)" @click="setDateRange(undefined, [-e, 'week'])" :key="e">
          {{e}}주
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown v-if="month > 0" variant="info" text="개월">
        <b-dropdown-item v-for="e in Array(month).fill(0).map((_, i) => i + 1)" @click="setDateRange(undefined, [-e, 'month'])" :key="e">
          {{e}}개월
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown v-if="absMonth > 0" variant="success" text="월">
        <b-dropdown-item v-for="m in monthPreset" @click="setDateRange(m, ['endOf', 'month'])" :key="m">
          {{ m.substring(0, 7) }}
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown v-if="year > 0" variant="primary" text="년">
        <b-dropdown-item v-for="e in Array(year).fill(0).map((_, i) => i + 1)" @click="setDateRange(undefined, [-e, 'year'])" :key="e">
          {{e}}년
        </b-dropdown-item>
      </b-dropdown>
    </b-button-group>
    <slot name="end"></slot>
  </b-form>
</template>

<script>

import * as moment from "moment-timezone";

export default {
  name: 'DateFromTo',
  model: {prop: 'value', event: 'change'},
  props: {
    from: String,
    to: String,
    init: String, // '1 week' or '7 day,1 day' 의 형태(from or from,to)
    inputOnly: {type: Boolean, default: false},
    calendar: {type: Boolean, default: true},
    all: {type: Boolean, default: false},
    today: {type: Boolean, default: true},
    yesterday: {type: Boolean, default: true},
    twoDays: {type: Boolean, default: false},
    day: {type: Number, default: 0},
    week: {type: Number, default: 5},
    month: {type: Number, default: 6},
    absMonth: {type: Number, default: 6}, // absolute month
    year: {type: Number, default: 0},
  },
  data() {
    return {
      monthPreset: [],
    }
  },
  async created() {
    let monSt = moment().startOf('month');
    while (monSt >= moment().add(-this.absMonth, 'month')) {
      this.monthPreset.push(monSt.format('YYYY-MM-DD'));
      monSt.add(-1, 'month');
    }
    if (this.init) {
      setTimeout(() => this.setRangeStr(this.init), 1); // DS 등에서 적용하기위해 timeout 추가
      // this.setRangeStr(this.init)
    }
  },
  computed:{
    _from: {
      get() {
        return this.from;
      },
      set(v) {
        this.$emit('update:from', v);
      }
    },
    _to: {
      get() {
        return this.to;
      },
      set(v) {
        this.$emit('update:to', v);
      }
    }
  },
  methods: {
    onEnter(where) {
      this.$emit('enter');
      this.$emit('enter-' + where);
    },
    setAllRange() {
      this._from = '2015-06-01';
      this._to = moment().format('YYYY-MM-DD');
    },
    setRangeStr(str) {
      const [from, to] = str.split(',');
      const [d, interval] = from.split(' ');
      this._from = moment().add(-d, interval).format('YYYY-MM-DD');
      if (to) {
        const [d, interval] = to.split(' ');
        this._to = moment().add(-d, interval).format('YYYY-MM-DD');
      } else {
        this._to = moment().format('YYYY-MM-DD');
      }
    },
    setDateRange(st, ed) {
      if (typeof (ed) === 'string') {
        this._from = st;
        this._to = ed;
      } else if (typeof (ed[0]) === 'number') {
        if (this.$utils.typeOf(st) === 'array') {
          st = moment().add(...st);
        }
        let dayA = moment(st).format('YYYY-MM-DD');
        let dayB = moment(st).add(...ed).add(ed[1] !== 'day' ? 1 : 0, 'day').format('YYYY-MM-DD');
        this._from = ed[0] >= 0 ? dayA : dayB;
        this._to = ed[0] >= 0 ? dayB : dayA;
      } else {
        let dayA = moment(st).format('YYYY-MM-DD');
        let dayB = moment(st)[ed[0]](ed[1]).format('YYYY-MM-DD');
        this._from = dayA;
        this._to = dayB;
      }
    },
  },
}
</script>
