<template>
  <div class="">
    <div class="clearfix" v-if="!hideButtons">
      <b-button class="mr-1 mb-1" size="sm" variant="warning" @click="brands.splice(0, brands.length)" v-b-tooltip.left="`${value && value.length || 0} 개 선택됨`">초기화</b-button>

      <template v-if="$R('PARTNER_R')">
        <b-dropdown class="mr-1 mb-1" size="sm" variant="light" :no-caret="compress">
          <template v-slot:button-content>
            타입
          </template>
          <b-dropdown-item @click="setBrand({brand_type: 'luxury'}, $event)">럭셔리</b-dropdown-item>
          <b-dropdown-item @click="setBrand({brand_type: 'contemporary'}, $event)">컨템포러리</b-dropdown-item>
          <b-dropdown-item @click="setBrand({brand_type: 'golf'}, $event)">골프</b-dropdown-item>
          <b-dropdown-item @click="setBrand({brand_type: 'kids'}, $event)">키즈</b-dropdown-item>
          <b-dropdown-item @click="setBrand({brand_type: 'living'}, $event)">홈/리빙</b-dropdown-item>
          <b-dropdown-item @click="setBrand({brand_type: 'sports'}, $event)">스포츠/웰니스</b-dropdown-item>
          <b-dropdown-item @click="setBrand({brand_type: 'digital'}, $event)">테크/디지털</b-dropdown-item>
          <b-dropdown-item @click="setBrand({brand_type: 'pet'}, $event)">반려동물</b-dropdown-item>
          <b-dropdown-item @click="setBrand({brand_type: 'art'}, $event)">아트</b-dropdown-item>
          <b-dropdown-item @click="setBrand({brand_type: 'etc'}, $event)">기타</b-dropdown-item>
          <b-dropdown-item @click="setBrand({brand_type: ''}, $event)">미지정</b-dropdown-item>
        </b-dropdown>
      </template>

      <template v-if="compress">
        <b-dropdown v-for="[k, v] in Object.entries($utils.arr2multi(brandPreset.filter(b=>!~(b.hide||[]).indexOf($S.user.id)), 'btnType'))" class="mr-1 mb-1"
                    size="sm" :variant="k" :key="k">
          <template v-slot:button-content>
          </template>
          <template v-for="b in v">
            <b-dropdown-item v-if="!~(b.hide||[]).indexOf($S.user.id) || brandPresetEditIcon" :key="b._id"
                             @click="brandPresetEditIcon ? show({_id:b._id}) : setBrand(b.selBrand.join(','), $event)">{{ b.btnName }}
              <i v-if="brandPresetEditIcon" class="ml-1 fa fa-pencil"></i>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>
      <template v-else>
        <template v-for="b in brandPreset">
          <b-button class="mr-1 mb-1" size="sm" v-if="!~(b.hide||[]).indexOf($S.user.id) || brandPresetEditIcon" :variant="b.btnType" :key="b._id"
                    @click="brandPresetEditIcon ? show({_id:b._id}) : setBrand(b.selBrand.join(','), $event)">{{ b.btnName }}
            <i v-if="brandPresetEditIcon" class="fa fa-pencil"></i>
          </b-button>
        </template>
      </template>

      <div class="pull-right">
        <div class="float-right mb-1">
          <b-button class="mr-1" size="sm" :variant="compress ? 'dark' : 'light'" @click="toggleCompress"><i
            :class="`fa fa-${compress ? 'expand' : 'compress'}`"></i></b-button>
          <b-dropdown v-if="compress" class="" size="sm" variant="light" right>
            <template v-if="$R('META_W')">
              <b-dropdown-item :active="brandPresetEditIcon" @click="brandPresetEditIcon = !brandPresetEditIcon">
                <i class="fa fa-pencil"></i> preset 수정모드
              </b-dropdown-item>
              <b-dropdown-item @click="show({})"><i class="fa fa-plus"></i> preset 추가</b-dropdown-item>
            </template>
            <b-dropdown-item @click="showInput('main')"><i class="fa fa-filter"></i> Brand No 로 입력</b-dropdown-item>
            <b-dropdown-item @click="$utils.copyAlert(value.map(e => e.brand_no).join('\n'))"><i class="fa fa-copy"></i> 선택된 Brand No 복사</b-dropdown-item>
            <b-dropdown-item @click="showHelp()"><i class="fa fa-question"></i> 도움말</b-dropdown-item>
          </b-dropdown>
          <template v-else>
            <template v-if="$R('META_W')">
              <b-button class="mr-1" size="sm" :variant="brandPresetEditIcon ? 'dark' : 'light'" title="preset 수정모드"
                        @click="brandPresetEditIcon = !brandPresetEditIcon">
                <i class="fa fa-pencil"></i>
              </b-button>
              <b-button class="mr-1" size="sm" variant="light" title="preset 추가" @click="show({})">+</b-button>
            </template>
            <b-button class="mr-1" size="sm" variant="light" title="Brand No 로 입력" @click="showInput('main')">ID</b-button>
            <b-button class="" size="sm" variant="light" title="선택된 Brand No 복사" @click="$utils.copyAlert(value.map(e => e.brand_no).join('\n'))">
              <i class="fa fa-copy"></i>
            </b-button>
            <b-button size="sm" variant="light" title="도움말" @click="showHelp()"><i class="fa fa-question"></i></b-button>
          </template>
        </div>
      </div>
    </div>

    <v-select v-model="brands" :multiple="multiple" :options="filteredBrand"
              :placeholder="filteredBrand.length ? `전체 ${filteredBrand.length} 개 브랜드` : '로딩중...'"></v-select>

    <b-form v-if="!hideOptions" inline>
      <b-form-checkbox class="mr-3" size="sm" v-model="cond.hideDisabled">미사용 브랜드 제외</b-form-checkbox>
    </b-form>

    <b-modal title="Brand Preset 설정" size="lg" v-model="modal.preset" ok-title="저장">
      <div class="clearfix mb-1">
        <div class="mt-2 pull-left label-sm">Brand를 선택해주세요.</div>
        <div class="pull-right">
          <b-form inline>
            <b-form-checkbox class="mr-3" size="sm" v-model="cond.hideDisabledModal">미사용 브랜드 제외</b-form-checkbox>
            <b-button class="ml-1" size="sm" variant="warning" @click="brandPresetModal.selBrand.splice(0, brandPresetModal.selBrand.length)">초기화</b-button>
            <b-button class="ml-1" size="sm" variant="outline-primary" @click="copyFromMain()">선택되어있는 값 가져오기</b-button>
            <b-button class="ml-1" size="sm" variant="outline-success" @click="showInput('preset')">Brand No 로 추가하기</b-button>
          </b-form>
        </div>
      </div>

      <v-select v-model="brandPresetModal.selBrand" multiple :options="filteredBrandModal" placeholder="Brand를 선택해주세요."></v-select>

      <div class="mt-2 label-sm">버튼 이름을 선택해주세요</div>
      <b-input v-model="brandPresetModal.btnName"></b-input>

      <div class="mt-2 label-sm">전체 인원에게 공유할지를 선택해주세요</div>
      <b-form-radio-group class="col-form-label" v-model="brandPresetModal.btnShare" :options="[
          {text: '전체공유', value: 'y'},
          {text: '개인용', value: 'n'}
        ]"></b-form-radio-group>

      <div class="mt-2 label-sm">버튼 색상을 선택해주세요</div>
      <b-button class="mr-1 mb-1" size="sm" v-for="c in ['primary','secondary','success','warning','danger','info','light','dark']"
                :variant="`${brandPresetModal.btnShare === 'y' ? '' : 'outline-'}${c}`" :key="c"
                @click="setBtnType(`${brandPresetModal.btnShare === 'y' ? '' : 'outline-'}${c}`)">{{ c }}
      </b-button>

      <div class="mt-2 label-sm">선택한 버튼의 형태를 확인해주세요</div>
      <b-button size="sm" class="mr-1 mb-2" :variant="brandPresetModal.btnType">{{ brandPresetModal.btnName }}</b-button>

      <b-checkbox v-model="brandPresetModal.hideOnMe">이 버튼을 나에게서 숨깁니다.</b-checkbox>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="$R('DEV')" variant="outline-light" @click="$modal.show({title: 'JSON 보기', type: 'json', item: brandPresetModal})">
          JSON
        </b-button>
        <b-button v-if="brandPresetModal._id" variant="success" @click="updateBrandPreset">
          수정
        </b-button>
        <b-button v-else variant="primary" @click="addBrandPreset">
          저장
        </b-button>
        <b-button v-if="brandPresetModal._id" variant="danger" @click="deleteBrandPreset">
          삭제
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>

    <b-modal title="Brand No 로 Brand 선택" size="lg" v-model="modal.input">
      Brand No 를 입력해주세요.
      <b-textarea v-model="brand_nos" placeholder="ex) 1, 2, 3" rows="5"></b-textarea>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="setBrandNos()">
          적용
        </b-button>
        <b-button variant="success" @click="setBrandNos(true)">
          추가
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>

    <b-modal title="도움말" size="lg" v-model="modal.help">
      <div class="mb-2">
        <span class="btn btn-light btn-sm"><i class="fa fa-compress"></i></span> : Brand Preset 버튼들을 축소형태로 볼 수 있습니다
      </div>
      <div class="mb-2" v-if="$R('META_W')">
        <div class="mb-2">
          <span class="btn btn-light btn-sm"><i class="fa fa-pencil"></i></span> : Brand Preset 수정모드로 전환합니다
        </div>
        <div class="mb-2">
          <span class="btn btn-light btn-sm"><i class="fa fa-plus"></i></span> : Brand Preset 을 추가합니다
        </div>
      </div>
      <div class="mb-2">
        <span class="btn btn-light btn-sm"><i class="fa fa-filter"></i></span> : Brand ID 를 직접 입력해서 추가 가능합니다
      </div>
      <div class="mb-2">
        <span class="btn btn-light btn-sm"><i class="fa fa-copy"></i></span> : 현재 입력되어있는 Brand ID 를 복사합니다
      </div>
      <div class="mb-2">
        Brand Preset 을 Shift Key 를 누르면서 Click : 기존 Brand 에 추가합니다 (OR 조건)
      </div>
      <div class="mb-2">
        Brand Preset 을 Ctrl Key 를 누르면서 Click : 기존 Brand 과 공통인 Brand 만 남겨둡니다 (AND 조건)
      </div>
      <div class="mb-2">
        Brand Preset 을 Ctrl Key + Shift Key 를 누르면서 Click : 기존 Brand 과 공통인 Brand 만 제거합니다
      </div>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "BrandPreset",
  model: {prop: 'value', event: 'change'},
  props: {
    value: null, // multiple 일 땐 Array, multiple 이 아닐 땐 Object 라서 이렇게 지정
    hideButtons: Boolean,
    hideOptions: {type: Boolean, default: false},
    hideDisabled: {type: Boolean, default: false},
    multiple: {type: Boolean, default: true},
  },
  data() {
    return {
      brand: [],
      filteredBrand: [],
      filteredBrandModal: [],
      brandMap: {},
      brandPreset: [],
      brandPresetModalBase: {
        _id: '',
        selBrand: [],
        btnName: '버튼명',
        btnShare: 'n',
        btnType: 'light',
        hideOnMe: false,
      },
      brandPresetModal: {selBrand: [],},
      brandPresetEditIcon: false,
      compress: false,
      brand_nos: '',
      input_target: 'main',
      modal: {preset: false, input: false, help: false},
      cond: {hideDisabled: false, hideDisabledModal: false},
    }
  },
  async created() {
    this.cond.hideDisabled = this.hideDisabled;

    // let meta = await this.$api.getPubMeta('brand');
    // if (!meta) return;
    //
    // this.$utils.getStatus(this.$options.name, this, 'compress');
    //
    // this.brand = meta.brand.sort((a, b) => (a.disabled ? 1000000 : 0) + a.brand_no - (b.disabled ? 1000000 : 0) - b.brand_no);
    // this.brand.forEach(e => {
    //   e.value = e.brand_no;
    //   e.label = `${e.disabled ? `[미사용] ` : ''}${e.brand_no}. ${e.brand_nm} (${e.brand_nm_kr})`;
    //   this.brandMap[e.brand_no] = e;
    // });

    this.$utils.getStatus(this.$options.name, this, 'compress');

    // let meta = await this.$api.getPubMeta('brand');
    // if (!meta) return;
    await this.$api.getAllMeta();
    this.brand = this.$S.m.brand.list.slice();
    this.brandMap = this.$S.m.brand.map;

    this.setFilteredBrand();
    this.brandPreset = await this.$api.getBrandPreset();
  },
  watch: {
    brandPresetModal: {
      deep: true,
      handler(v) {
        this.brandPresetModal.btnType = (v.btnShare === 'y' ? '' : 'outline-') + this.brandPresetModal.btnType.replace('outline-', '');
      },
    },
    cond: {
      deep: true,
      handler() {
        this.setFilteredBrand();
      }
    }
  },
  computed: {
    brands: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    show(params) {
      this.modal.preset = true;
      if (params._id) {
        this.brandPresetModal = this.$utils.clone(this.brandPreset.filter(e => e._id === params._id)[0]);
        this.brandPresetModal.selBrand = this.brandPresetModal.selBrand.map(e => this.brandMap[e]);
      } else {
        this.brandPresetModal = this.$utils.clone(this.brandPresetModalBase);
      }
    },
    showInput(target) {
      this.input_target = target;
      this.modal.input = true;
      this.brand_nos = '';
    },
    async hide() {
      this.modal.preset = false;
      this.brandPreset = await this.$api.getBrandPreset(); // 수정 후 갱신
    },
    showHelp() {
      this.modal.help = true;
    },
    setBrand(obj, event) {
      let brands;
      if (typeof (obj) === 'string') {
        const brandNos = obj.split(',').map(e => +e);
        brands = this.filteredBrand.filter(e => brandNos.includes(e.value));
      } else {
        let brand = this.filteredBrand;
        Object.entries(obj).forEach(([k, v]) => {
          if (this.$utils.typeOf(v) === 'array') {
            // array type
            brand = brand.filter(e => v.includes(e[k]));
          } else {
            brand = brand.filter(e => e[k] === v);
          }
        });
        brands = brand;
      }

      if (event && event.ctrlKey && event.shiftKey) { // 기존 shops 에서 선택된 brands 를 제거 ex) 1,2,3 선택중 ctrl+shift 로 3,4 를 선택하면 1,2 만 남는다.
        const excludedBrands = this.value.filter(e => !brands.includes(e)); // 새로 선택한 brands 에 포함된 shop 을 제거
        this.value.splice(0, this.value.length, ...excludedBrands);
      } else if (event && event.shiftKey) { // 기존 brands 에 추가
        brands = brands.filter(e => !~this.value.indexOf(e)); // 기존에 없는 brand 만
        this.value.splice(0, this.value.length, ...this.value.concat(brands));
      } else if (event && event.ctrlKey) { // 기존 brands 와 and 조건
        brands = brands.filter(e => this.value.includes(e)); // 기존에 있는 brand 만
        this.value.splice(0, this.value.length, ...brands);
      } else {
        this.value.splice(0, this.value.length, ...brands);
      }
    },
    setFilteredBrand() {
      this.filteredBrand = this.brand.filter(e => !this.cond.hideDisabled || !e.disabled);
      this.filteredBrandModal = this.brand.filter(e => !this.cond.hideDisabledModal || !e.disabled);
    },
    setBtnType(t) {
      this.brandPresetModal.btnType = t;
    },
    async addBrandPreset() {
      if (!this.brandPresetModal.btnName) return alert('버튼 이름을 입력해주세요');
      if (!this.brandPresetModal.selBrand.length) return alert('Brand를 선택해주세요');
      let j = await this.$api.postJson('/meta/brandPreset', {type: 'add', ...this.brandPresetModal, selBrand: this.brandPresetModal.selBrand.set('brand_no')});
      if (j) {
        // alert ('추가되었습니다');
        this.hide();
      }
    },
    async updateBrandPreset() {
      if (!this.brandPresetModal.btnName) return alert('버튼 이름을 입력해주세요');
      if (!this.brandPresetModal.selBrand.length) return alert('Brand를 선택해주세요');
      let j = await this.$api.postJson('/meta/brandPreset', {type: 'update', ...this.brandPresetModal, selBrand: this.brandPresetModal.selBrand.set('brand_no')});
      if (j) {
        // alert ('수정되었습니다');
        this.hide();
      }
    },
    async deleteBrandPreset() {
      if (!confirm('정말로 삭제하시겠습니까?')) return;
      let j = await this.$api.postJson('/meta/brandPreset', {type: 'delete', _id: this.brandPresetModal._id});
      if (j) {
        alert('삭제되었습니다');
        this.hide();
      }
    },
    copyFromMain() {
      this.brandPresetModal.selBrand.splice(0, this.brandPresetModal.selBrand.length, ...this.value);
    },
    setBrandNos(append) {
      if (!this.brand_nos.trim()) return alert('Brand No 를 하나 이상 입력해주세요');
      let brand_nos = this.brand_nos.trim().split(/\D+/g).map(e => +e);
      if (append) {
        brand_nos = Array.from(new Set(brand_nos.concat(this.value.map(e => e.brand_no))));
      }
      let brands = brand_nos.map(e => this.brandMap[e]).filter(e => e);
      if (this.input_target === 'preset') {
        this.brandPresetModal.selBrand.splice(0, this.brandPresetModal.selBrand.length, ...brands);
      } else {
        this.value.splice(0, this.value.length, ...brands);
      }
      this.modal.input = false;
    },
    toggleCompress() {
      this.compress = !this.compress;
      this.$utils.setStatus(this.$options.name, this, 'compress');
    },
    setHideDisabled(hideDisabled) {
      this.cond.hideDisabled = hideDisabled;
    }
  },
}
</script>
