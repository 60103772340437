<template>
  <div class="">
    <div class="clearfix">
      <b-button class="mr-1 mb-1" size="sm" variant="warning" @click="cates.splice(0, cates.length)" v-b-tooltip.left="`${value && value.length || 0} 개 선택됨`">
        초기화
      </b-button>

      <b-dropdown class="mr-1 mb-1" size="sm" variant="light" :no-caret="compress">
        <template v-slot:button-content>
          분류
        </template>
        <b-dropdown-item @click="setCategory({code: 'CL'}, $event)">의류(CL)</b-dropdown-item>
        <b-dropdown-item @click="setCategory({code: 'SH'}, $event)">슈즈(SH)</b-dropdown-item>
        <b-dropdown-item @click="setCategory({code: 'BA'}, $event)">가방(BA)</b-dropdown-item>
        <b-dropdown-item @click="setCategory({code: 'AC'}, $event)">악세서리(AC)</b-dropdown-item>
        <b-dropdown-item @click="setCategory({code: 'JW'}, $event)">쥬얼리(JW)</b-dropdown-item>
      </b-dropdown>

      <template v-if="compress">
        <b-dropdown v-for="[k, v] in Object.entries($utils.arr2multi(categoryPreset.filter(b=>!~(b.hide||[]).indexOf($S.user.id)), 'btnType'))"
                    class="mr-1 mb-1"
                    size="sm" :variant="k" :key="k">
          <template v-slot:button-content>
          </template>
          <template v-for="b in v">
            <b-dropdown-item v-if="!~(b.hide||[]).indexOf($S.user.id) || categoryPresetEditIcon" :key="b._id"
                             @click="categoryPresetEditIcon ? show({_id:b._id}) : setCategory(b.selCategory.join(','), $event)">{{ b.btnName }}
              <i v-if="categoryPresetEditIcon" class="ml-1 fa fa-pencil"></i>
            </b-dropdown-item>
          </template>
        </b-dropdown>
      </template>
      <template v-else>
        <template v-for="e in categoryPreset">
          <b-button class="mr-1 mb-1" size="sm" v-if="!~(e.hide||[]).indexOf($S.user.id) || categoryPresetEditIcon"
                    :variant="e.btnType" :key="e._id"
                    @click="categoryPresetEditIcon ? show({_id:e._id}) : setCategory(e.selCategory.join(','), $event)">{{ e.btnName }}
            <i v-if="categoryPresetEditIcon" class="fa fa-pencil"></i>
          </b-button>
        </template>
      </template>

      <div class="float-right mb-1">
        <b-button class="mr-1" size="sm" :variant="compress ? 'dark' : 'light'" @click="toggleCompress"><i
          :class="`fa fa-${compress ? 'expand' : 'compress'}`"></i></b-button>
        <b-dropdown v-if="compress" class="" size="sm" variant="light" right>
          <b-dropdown-item :active="categoryPresetEditIcon" @click="categoryPresetEditIcon = !categoryPresetEditIcon">
            <i class="fa fa-pencil"></i> preset 수정모드
          </b-dropdown-item>
          <b-dropdown-item @click="show({})"><i class="fa fa-plus"></i> preset 추가</b-dropdown-item>
          <b-dropdown-item @click="showInput('main')"><i class="fa fa-filter"></i> Category Code 로 입력</b-dropdown-item>
          <b-dropdown-item @click="$utils.copyAlert(value.map(e => e.category).join('\n'))"><i class="fa fa-copy"></i> 선택된 Category Code 복사</b-dropdown-item>
        </b-dropdown>
        <template v-else>
          <b-button class="mr-1" size="sm" :variant="categoryPresetEditIcon ? 'dark' : 'light'" title="preset 수정모드"
                    @click="categoryPresetEditIcon = !categoryPresetEditIcon">
            <i class="fa fa-pencil"></i>
          </b-button>
          <b-button class="mr-1" size="sm" variant="light" title="preset 추가" @click="show({})"><i class="fa fa-plus"></i></b-button>
          <b-button class="mr-1" size="sm" variant="light" title="Category Code 로 입력" @click="showInput('main')">ID</b-button>
          <b-button class="" size="sm" variant="light" title="선택된 Category Code 복사" @click="$utils.copyAlert(value.map(e => e.category).join('\n'))">
            <i class="fa fa-copy"></i>
          </b-button>
        </template>
      </div>
    </div>

    <v-select v-model="cates" multiple :options="filteredCategory"
              :placeholder="category.length ? `전체 ${filteredCategory.length} 개 카테고리` : '로딩중...'"></v-select>

    <b-form v-if="!hideOptions" inline>
      <b-form-radio-group class="mr-1" size="sm" v-model="cond.finalCate" :options="[
        {text: '전체', value: 'ALL'}, {text: '최종 제외', value: 'exclude'}, {text: '최종만', value: 'only'}
      ]"></b-form-radio-group>
      <b-form-checkbox class="mr-3" size="sm" v-model="cond.includeNewinSale">NewIn/Sale</b-form-checkbox>
      <b-form-checkbox class="" size="sm" v-model="cond.includeLastOne">Last One</b-form-checkbox>
    </b-form>

    <b-modal title="카테고리 Preset 설정" size="lg" v-model="modal.preset" ok-title="저장">
      <div class="clearfix mb-1">
        <div class="mt-2 pull-left label-sm">카테고리를 선택해주세요.</div>
        <div class="pull-right">
          <b-button class="ml-1" size="sm" variant="warning" @click="categoryPresetModal.selCategory.splice(0, categoryPresetModal.selCategory.length)">초기화
          </b-button>
          <b-button class="ml-1" size="sm" variant="outline-primary" @click="copyFromMain()">선택되어있는 값 가져오기</b-button>
          <b-button class="ml-1" size="sm" variant="outline-success" @click="showInput('preset')">Category 코드로 추가하기</b-button>
        </div>
      </div>
      <v-select v-model="categoryPresetModal.selCategory" multiple :options="category" placeholder="카테고리를 선택해주세요."></v-select>

      <div class="mt-2 label-sm">버튼 이름을 선택해주세요</div>
      <b-input v-model="categoryPresetModal.btnName"></b-input>


      <div class="mt-2 label-sm">전체 인원에게 공유할지를 선택해주세요</div>
      <b-form-radio-group class="col-form-label" v-model="categoryPresetModal.btnShare" :options="[
          {text: '전체공유', value: 'y'},
          {text: '개인용', value: 'n'}
        ]"></b-form-radio-group>

      <div class="mt-2 label-sm">버튼 색상을 선택해주세요</div>
      <b-button class="mr-1 mb-1" size="sm" v-for="c in ['primary','secondary','success','warning','danger','info','light','dark']"
                :variant="`${categoryPresetModal.btnShare === 'y' ? '' : 'outline-'}${c}`" :key="c"
                @click="setBtnType(`${categoryPresetModal.btnShare === 'y' ? '' : 'outline-'}${c}`)">{{ c }}
      </b-button>

      <div class="mt-2 label-sm">선택한 버튼의 형태를 확인해주세요</div>
      <b-button size="sm" class="mr-1 mb-2" :variant="categoryPresetModal.btnType">{{ categoryPresetModal.btnName }}</b-button>
      <b-checkbox v-model="categoryPresetModal.hideOnMe">이 버튼을 나에게서 숨깁니다.</b-checkbox>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="$R('DEV')" variant="outline-light" @click="$modal.show({title: 'JSON 보기', type: 'json', item: categoryPresetModal})">
          JSON
        </b-button>
        <b-button v-if="categoryPresetModal._id" variant="success" @click="updateCategoryPreset">
          수정
        </b-button>
        <b-button v-else variant="primary" @click="addCategoryPreset">
          저장
        </b-button>
        <b-button v-if="categoryPresetModal._id" variant="danger" @click="deleteCategoryPreset">
          삭제
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>

    <b-modal title="Category 코드로 Category 선택" size="lg" v-model="modal.input">
      Category 코드 를 입력해주세요.
      <b-textarea v-model="categories" placeholder="ex) 009002, 009007" rows="5"></b-textarea>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button variant="primary" @click="setCategories()">
          적용
        </b-button>
        <b-button variant="success" @click="setCategories(true)">
          추가
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          취소
        </b-button>
      </template>
    </b-modal>
  </div>
</template>

<script>

export default {
  name: "CategoryPreset",
  model: {prop: 'value', event: 'change'},
  props: {
    value: Array,
    hideOptions: {type: Boolean, default: false},
    finalCate: {type: String, default: 'ALL'},
    includeNewinSale: {type: Boolean, default: false},
    includeLastOne: {type: Boolean, default: false}
  },
  data() {
    return {
      category: [],
      filteredCategory: [],
      categoryMap: {},
      categoryPreset: [],
      categoryPresetModalBase: {
        _id: '',
        selCategory: [],
        btnName: '버튼명',
        btnShare: 'n',
        btnType: 'light',
        hideOnMe: false,
      },
      categoryPresetModal: {selCategory: [],},
      categoryPresetEditIcon: false,
      compress: false,
      cond: {finalCate: 'ALL', includeNewinSale: false, includeLastOne: false},
      categories: '',
      input_target: 'main',
      modal: {preset: false, input: false},
    }
  },
  async created() {
    // let meta = await this.$api.getPubMeta('category');
    // if (!meta) return;
    //
    // this.cond.finalCate = this.finalCate;
    // this.cond.includeLastOne = this.includeLastOne;
    // this.cond.includeNewinSale = this.includeNewinSale;
    //
    // this.$utils.getStatus(this.$options.name, this, 'compress');
    //
    // this.category = meta.category.filter(e => e.category.match(/^(009|0[1-9][0-9])/)).map(e => {
    //   return this.categoryMap[e.category] = {
    //     ...e,
    //     value: e.category,
    //     parent: e.category.substring(0, e.category.length - 3),
    //     label: `${e.category} (${e.category_nm})`
    //   };
    // }).sort((a, b) => (a.value.length - b.value.length) * 10 + a.value.localeCompare(b.value));
    // let parentCateMap = this.$utils.arr2multi(this.category, 'parent');
    // // 하위 카테고리 수를 넣기 위해 label 을 재지정한다.
    // this.category.forEach(e => {
    //   let p = parentCateMap[e.category];
    //   e.label = `${e.category} (${e.category_nm}, ${p ? `하위 ${p.length} 개` : '최종'})`;
    // });

    this.$utils.getStatus(this.$options.name, this, 'compress');

    // let meta = await this.$api.getPubMeta('category');
    // if (!meta) return;
    await this.$api.getAllMeta();
    this.category = this.$S.m.category.list.map(e => ({...e, label: `${e.category} (${e.category_nm}, ${e.childCnt ? `하위 ${e.childCnt} 개` : '최종'})`}));
    this.categoryMap = this.$utils.arr2map(this.category, 'category');

    this.setFilteredCategory();

    this.categoryPreset = await this.$api.getCategoryPreset();
  },
  watch: {
    categoryPresetModal: {
      deep: true,
      handler(v) {
        this.categoryPresetModal.btnType = (v.btnShare === 'y' ? '' : 'outline-') + this.categoryPresetModal.btnType.replace('outline-', '');
      },
    },
    cond: {
      deep: true,
      handler() {
        this.setFilteredCategory();
      }
    }
  },
  computed: {
    cates: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  methods: {
    show(params) {
      this.modal.preset = true;
      if (params._id) {
        this.categoryPresetModal = this.$utils.clone(this.categoryPreset.filter(e => e._id === params._id)[0]);
        this.categoryPresetModal.selCategory = this.categoryPresetModal.selCategory.map(e => this.categoryMap[e]);
      } else {
        this.categoryPresetModal = this.$utils.clone(this.categoryPresetModalBase);
      }
    },
    showInput(target) {
      this.input_target = target;
      this.modal.input = true;
      this.categories = '';
    },
    async hide() {
      this.modal.preset = false;
      this.categoryPreset = await this.$api.getCategoryPreset();
    },
    setCategory(obj, event) {
      let categories;
      if (typeof (obj) === 'string') {
        categories = this.category.filter(e => ~obj.split(',').indexOf(e.category));
      } else {
        categories = this.category.filter(e => {
          if (e.category.slice(0, 3).in('009', '010', '011') && e.category.length === 6 ||
            e.category.slice(0, 6) === '011004' && e.category.length === 9 ||
            e.category.slice(0, 6) === '040002' && e.category.length > 6 ||
            e.category.in('040002005003', '040006005003', '040006005004') ||
            e.category.match(/^040011(002|003|005|006|010|011)/) ||
            e.category.slice(0, 9).match(/^040(003|005|009|010)(002|003|004)/) && e.category.length > 6 ||
            e.category.slice(0, 9).match(/^040(004|006)(002|003)/) && e.category.length > 6
          ) {
            const code = this.categoryToCode(e.category);
            if (obj.code === code.slice(1, 3)) return true;
          }
          return false;
        });
      }


      if (event && event.ctrlKey && event.shiftKey) { // 기존 categories 에서 선택된 categories 를 제거 ex) 1,2,3 선택중 ctrl+shift 로 3,4 를 선택하면 1,2 만 남는다.
        const excludedCategories = this.value.filter(e => !categories.includes(e)); // 새로 선택한 categories 에 포함된 shop 을 제거
        this.value.splice(0, this.value.length, ...excludedCategories);
      } else if (event && event.shiftKey) { // 기존 categories 에 추가
        categories = categories.filter(e => !~this.value.indexOf(e)); // 기존에 없는 category 만
        this.value.splice(0, this.value.length, ...this.value.concat(categories));
      } else if (event && event.ctrlKey) { // 기존 categories 와 and 조건
        categories = categories.filter(e => this.value.includes(e)); // 기존에 있는 category 만
        this.value.splice(0, this.value.length, ...categories);
      } else {
        this.value.splice(0, this.value.length, ...categories);
      }
    },
    setFilteredCategory() {
      this.filteredCategory = this.category.filter(e =>
        (this.cond.includeNewinSale || !e.category.match(/^...00[17]/)) &&
        (this.cond.finalCate === 'ALL' || (this.cond.finalCate === 'exclude' && !e.final) || (this.cond.finalCate === 'only' && e.final)) &&
        (this.cond.includeLastOne || !e.category.match(/^...(008|111)/))
      );
    },
    toggleCategory() {
      if (this.value.length === this.category.length) {
        this.value.splice(0, this.value.length);
      } else {
        this.value.splice(0, this.value.length, ...this.category.map(e => e));
      }
    },
    setBtnType(t) {
      this.categoryPresetModal.btnType = t;
    },
    async addCategoryPreset() {
      if (!this.categoryPresetModal.btnName) return alert('버튼 이름을 입력해주세요');
      if (!this.categoryPresetModal.selCategory.length) return alert('카테고리를 선택해주세요');
      let j = await this.$api.postJson('/meta/categoryPreset', {
        type: 'add', ...this.categoryPresetModal,
        selCategory: this.categoryPresetModal.selCategory.set('category')
      });
      if (j) {
        // alert ('추가되었습니다');
        this.hide();
      }
    },
    async updateCategoryPreset() {
      if (!this.categoryPresetModal.btnName) return alert('버튼 이름을 입력해주세요');
      if (!this.categoryPresetModal.selCategory.length) return alert('카테고리를 선택해주세요');
      let j = await this.$api.postJson('/meta/categoryPreset', {
        type: 'update', ...this.categoryPresetModal,
        selCategory: this.categoryPresetModal.selCategory.set('category')
      });
      if (j) {
        // alert ('수정되었습니다');
        this.hide();
      }
    },
    async deleteCategoryPreset() {
      if (!confirm('정말로 삭제하시겠습니까?')) return;
      let j = await this.$api.postJson('/meta/categoryPreset', {type: 'delete', _id: this.categoryPresetModal._id});
      if (j) {
        alert('삭제되었습니다');
        this.hide();
      }
    },
    copyFromMain() {
      this.categoryPresetModal.selCategory.splice(0, this.categoryPresetModal.selCategory.length, ...this.value);
    },
    setCategories(append) {
      if (!this.categories.trim()) return alert('Category 를 하나 이상 입력해주세요');
      let categories = this.categories.trim().split(/\D+/g).map(e => e);
      if (append) {
        categories = Array.from(new Set(categories.concat(this.value.map(e => e.category))));
      }
      let cates = categories.map(e => this.categoryMap[e]).filter(e => e);
      if (this.input_target === 'preset') {
        this.categoryPresetModal.selCategory.splice(0, this.categoryPresetModal.selCategory.length, ...cates);
      } else {
        this.value.splice(0, this.value.length, ...cates);
      }
      this.modal.input = false;
    },
    toggleCompress() {
      this.compress = !this.compress;
      this.$utils.setStatus(this.$options.name, this, 'compress');
    },
    /**
     * 009002003 등을 받아서 WCL 등을 반환한다.
     * 카테고리 확장이 되면서 011, 012, 013 등이 생겼고 각각 G(골프), B(뷰티), L(리빙) 으로 시작한다.
     * 골프의 002, 003 은 골프웨어라서 GCL 로 하고 나머지는 XX 로 한다.
     * 골프의 가방과 슈즈는 관세가 각 8%, 13% 인데 3차 카테고리라서 이 코드로는 분기할 수 없다. -> full category 가 들어온다고 가정하고 최대한 분기한다.
     *
     * @param {string} category
     * @return {string}
     */
    categoryToCode(category) {
      if (!category) return 'XXX';
      const cates = category.match(/.{3}/g) || [];
      const firstCode = {'009': 'W', '010': 'M', '011': 'G', '020': 'K', '030': 'L', '040': 'S', '050': 'D', '060': 'P', '070': 'A'}[cates[0]] || 'X';
      let code;
      if (['W', 'M'].includes(firstCode)) {
        if (['001', '007', '008'].includes(cates[1])) {
          code = {'002': 'CL', '003': 'SH', '004': 'BA', '005': 'AC', '006': 'JW'}[cates[2]];
        } else {
          code = {'002': 'CL', '003': 'SH', '004': 'BA', '005': 'AC', '006': 'JW'}[cates[1]];
        }
      } else if (firstCode === 'G') {
        if (['002', '003'].includes(cates[1])) {
          code = 'CL';
        } else if (cates[1] === '004') {
          code = {'002': 'BA', '003': 'SH', '004': 'AC', '006': 'AC'}[cates[2]];
        }
      } else if (firstCode === 'S') {
        if (cates[1] === '002') { // 골프
          code = {'002': 'CL', '003': 'CL'}[cates[2]];
          if (cates[2] === '005' && cates[3] === '003') code = 'SH'; // 골프화
        } else if (cates[1] === '003') { // 테니스
          code = {'002': 'CL', '003': 'CL', '004': 'SH'}[cates[2]];
        } else if (cates[1] === '004') { // 요가
          code = {'002': 'CL', '003': 'CL'}[cates[2]];
        } else if (cates[1] === '005') { // 러닝
          code = {'002': 'CL', '003': 'CL', '004': 'SH'}[cates[2]];
        } else if (cates[1] === '006') { // 스키
          code = {'002': 'CL', '003': 'CL'}[cates[2]];
          if (cates[2] === '005' && (cates[3] === '003' || cates[3] === '004')) code = 'SH'; // 부츠
        } else if (cates[1] === '009') { // 자전거
          code = {'002': 'CL', '003': 'CL', '004': 'SH'}[cates[2]];
        } else if (cates[1] === '010') { // 하이킹
          code = {'002': 'CL', '003': 'CL', '004': 'SH'}[cates[2]];
        } else if (cates[1] === '011') { // 축구
          code = {'002': 'CL', '003': 'SH', '005': 'CL', '006': 'SH', '010': 'CL', '011': 'SH'}[cates[2]];
        }
      }
      return firstCode + (code || 'XX');
    },
    setCateOtion(cond = {}) {
      this.cond = {...this.cond, ...cond}
    }
  },
}
</script>
