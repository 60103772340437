<template>
  <div>
    <b-modal v-if="type === 'ok'" :title="title" size="lg" v-model="visible" ok-title="닫기" ok-only>
      <div class="modal-base" v-html="html"></div>
    </b-modal>

    <b-modal v-if="type === 'diff'" title="수정이력 확인" size="lg" v-model="visible" ok-title="닫기" ok-only>
      <div v-if="diff">
        <template v-for="d in diff.slice().reverse()">
          <div class="clearfix">
            <div class="pull-right">
              <b-badge class="mr-1" variant="success">{{d._at}}</b-badge>
              <b-badge class="" variant="light">{{d._dt}}</b-badge>
              <!-- 관리자이거나 본인이면 rollback 버튼 -->
              <i v-if="db" class="fa fa-rotate-left text-muted ml-1" @click="rollbackDiff(d, item)"></i>
            </div>
            <div><b-badge variant="primary">{{d._name}}</b-badge></div>
            <span style="word-break: break-all;" v-html="formatDiff(d)"></span>
          </div>
          <hr/>
        </template>
      </div>
      <div v-else class="text-center">
        수정이력이 없습니다
      </div>
      <template v-slot:modal-footer="{ ok, cancel }">
        <b-button v-if="$R('CONFIRMED_R')" variant="outline-secondary" @click="optionXlsx(diff)">
          Option 이력
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <b-modal v-if="type === 'del'" title="삭제이력 확인" size="lg" v-model="visible" ok-title="닫기" ok-only>
      <div ref="json" v-html="formatJson(filterDelField(item), fieldMap)"></div>
    </b-modal>

    <b-modal v-if="type === 'json'" size="xl" v-model="visible">
      <template v-slot:modal-title>
        {{title}}
        <b-btn class="ml-4 mr-1" variant="light" size="sm" @click="toggleAll('show', $event)">모두 펼치기</b-btn>
        <b-btn class="mr-1" variant="light" size="sm" @click="toggleAll('default', $event)">기본 펼치기</b-btn>
        <b-btn variant="light" size="sm" @click="toggleAll('hide', $event)">모두 접기</b-btn>
      </template>
      <div ref="json" v-html="formatJson(item, fieldMap)"></div>
      <template v-slot:modal-footer="{cancel}">
        <b-button variant="outline-light" @click="$modal.show({title: 'JSON', type: 'jsonstr', item})">
          JSON
        </b-button>
        <b-button variant="secondary" @click="cancel()">
          닫기
        </b-button>
      </template>
    </b-modal>

    <b-modal v-if="type === 'jsonstr'" :title="title" size="lg" v-model="visible" ok-title="닫기" ok-only>
      <template v-slot:modal-title>
        {{title}}
        <b-btn class="ml-4 mr-1" variant="light" size="sm" @click="$utils.copyAlert(JSON.stringify(item, null, 2))">Copy with padding</b-btn>
        <b-btn class="" variant="light" size="sm" @click="$utils.copyAlert(JSON.stringify(item))">Copy minified</b-btn>
      </template>
      <pre style="word-break: break-all;white-space: break-spaces;">{{JSON.stringify(item, null, 2)}}</pre>
    </b-modal>

    <b-modal v-if="type === 'pre'" :title="title" size="lg" v-model="visible" ok-title="닫기" ok-only>
      <pre>{{text}}</pre>
    </b-modal>
  </div>
</template>

<script>
// we must import our Modal plugin instance
// because it contains reference to our Eventbus
import Modal from '@/plugins/modal.js';
import * as utils from '@/shared/utils'
import {down} from '@/shared/impexp'
import {postJson} from '@/shared/api'
import {Types} from "mongoose";

export default {
  name: 'ModalBase',
  // components: {},
  data() {
    return {
      visible: false,
      type: 'ok',
      title: '',
      html: '',
      text: '',
      item: {},
      fieldMap: null,
      diff: [],
      db: null,
      // adding callback function variable
      onConfirm: {}
    }
  },
  async beforeMount() {
    // here we need to listen for emited events
    // we declared those events inside our plugin
    Modal.EventBus.$on('show', (params) => {
      this.show(params)
    });
  },
  methods: {
    hide() {
      // this method is unchanged
      this.visible = false;
    },
    confirm() {
      // we must check if this.onConfirm is function
      if (typeof this.onConfirm === 'function') {
        // run passed function and then close the modal
        this.onConfirm(this);
        this.hide();
      } else {
        // we only close the modal
        this.hide();
      }
    },
    show(params) {
      // making modal visible
      this.visible = true;
      // setting title and text
      this.type = params.type || 'ok';
      this.title = params.type === 'json' && !params.title ? 'JSON Table' : params.title;
      this.html = params.html;
      this.text = params.text;
      this.item = params.item;
      this.item_org = params.item_org || {};
      this.fieldMap = params.fieldMap;
      this.diff = params.item && params.item._diff;
      this.db = params.db;
      this.collection = params.collection;
      this.colMap = params.colMap || [];
      // setting callback function
      this.onConfirm = params.onConfirm;
    },
    syncItem(res) {
      /** API 의 response로 온 결과값을 현재 item에 적용하여 싱크를 맞춘다 */
      res.$set && Object.entries(res.$set).forEach(([k, v]) => {
        this.item[k] = v;
        this.item_org[k] = v;
      });
      res.$unset && Object.entries(res.$unset).forEach(([k, v]) => {
        delete this.item[k];
        delete this.item_org[k];
      });
      if (!this.item['_diff']) this.item['_diff'] = [];
      if (!this.item_org['_diff']) this.item_org['_diff'] = [];
      this.item['_diff'].push(res.$push);
      this.item_org['_diff'].push(res.$push);
    },
    formatDiff(diff) {
      const html = v => typeof v === 'string' ? v.escapeHtml() : v;
      return Object.entries(diff).map(([k, v]) => {
        if (('_t,_dt,_d,_bef_dt,_at,_ip,_server_ip,_ct,_cdt,_cat,_mt,_mdt,_mat,' +
          '_org,_id,_uid,_name,_src,_mapped,modified,confirmed').split(',').includes(k)) return;
        if (k === 'src_not_found') {
          if (v === true) return `<span class="badge badge-light">${k}</span> <span class="badge badge-danger">원본정보 사라짐</span>`;
          else if (v.deleted === true) return `<span class="badge badge-light">${k}</span> <span class="badge badge-success">원본정보 발견됨</span>`;
        }
        if (k === 'options') {
          if (utils.typeOf(v) !== 'array') return;
          return `<span class="badge badge-light">${k}</span><br/>` + v.map(e => {
            if (~Object.keys(e).indexOf('created')) return `&nbsp;&nbsp;&nbsp;<span class="badge badge-success">옵션 생성됨</span> <small>${e.created.optnm}</small>:<span class="badge badge-light">${e.created.Size}</span> <small>재고</small>:<span class="badge badge-success">${e.created.stock}</span> <small>price</small>:<small>${utils.rnc(e.created.goods_price, 2)}</small>`;
            if (~Object.keys(e).indexOf('deleted')) return `&nbsp;&nbsp;&nbsp;<span class="badge badge-danger">옵션 삭제됨</span> <small>${e.deleted.optnm}</small>:<span class="badge badge-light">${e.deleted.Size}</span> <small>재고</small>:<span class="badge badge-success">${e.deleted.stock}</span> <small>price</small>:<small>${utils.rnc(e.deleted.goods_price, 2)}</small>`;
            if (e.Size) {
              let str = `&nbsp;&nbsp;&nbsp;<span class="badge badge-secondary">옵션 변경됨</span> <small>사이즈</small><span class="badge badge-light">${e.Size}</span><br/>`;
              str += Object.entries(e).filter(([k]) => k !== 'Size').map(([k, v]) => {
                if (~Object.keys(v).indexOf('created')) return `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="badge badge-light">${k}</span> <span class="badge badge-success">생성됨</span> <small>${v.created}</small>`;
                if (~Object.keys(v).indexOf('deleted')) return `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="badge badge-light">${k}</span> <span class="badge badge-danger">삭제됨</span> <small>${v.deleted}</small>`;
                if (~Object.keys(v).indexOf('set')) return `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="badge badge-light">${k}</span> <span class="badge warning">갱신됨</span> <small>${v.set}</small>`;
                return `&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;<span class="badge badge-light">${k}</span> <span class="badge badge-secondary">변경됨</span> '${v.updated_from}' ⇒ '${v.updated_to}'`;
              }).join('<br/>');
              return str;
            }
          }).join('<br/>');
        }
        if (k === 'img_urls') {
          if (utils.typeOf(v) !== 'array') return;
          return `<span class="badge badge-light">${k}</span><br/>` + v.map(e => {
            if (~Object.keys(e).indexOf('created')) return `&nbsp;&nbsp;&nbsp;<span class="badge badge-success">이미지 생성됨</span> <a href="${e.created}" target="_blank">${e.created}</a>`;
            if (~Object.keys(e).indexOf('deleted')) return `&nbsp;&nbsp;&nbsp;<span class="badge badge-danger">이미지 삭제됨</span> <a href="${e.deleted}" target="_blank">${e.deleted}</a>`;
            if (~Object.keys(e).indexOf('set')) return `&nbsp;&nbsp;&nbsp;<span class="badge badge-warning">이미지 갱신됨</span> <a href="${e.set}" target="_blank">${e.set}</a>`;
          }).join('<br/>');
        }
        if (k === 'uploaded_images') {
          if (utils.typeOf(v) !== 'array') return;
          return `<span class="badge badge-light">${k}</span><br/>` + v.map(e => {
            if (~Object.keys(e).indexOf('created')) return `&nbsp;&nbsp;&nbsp;<span class="badge badge-success">이미지 생성됨</span> <a href="${e.created.org.url}" target="_blank">${e.created.org.url}</a>`;
            if (~Object.keys(e).indexOf('deleted')) return `&nbsp;&nbsp;&nbsp;<span class="badge badge-danger">이미지 삭제됨</span> <a href="${e.deleted.org.url}" target="_blank">${e.deleted.org.url}</a>`;
          }).join('<br/>');
        }
        if (k === '_desc') { // 비교 없는 일방적 업데이트시 설명으로 대체한다.
          return `<span class="badge badge-light">${this.colMap[k] || k}</span> ${html(v)}`;
        }
        if (utils.typeOf(v) === 'object') { // object
          if (~Object.keys(v).indexOf('created')) {
            return `<span class="badge badge-light">${this.colMap[k] || k}</span> <span class="badge badge-success">생성됨</span> '${html(v.created)}'`;
          } else if (~Object.keys(v).indexOf('deleted')) {
            return `<span class="badge badge-light">${this.colMap[k] || k}</span> <span class="badge badge-danger">제거됨</span> '${html(v.deleted)}'`;
          } else if (~Object.keys(v).indexOf('set')) {
            return `<span class="badge badge-light">${this.colMap[k] || k}</span> <span class="badge badge-warning">SET</span> '${html(v.set)}'`;
          } else if (~Object.keys(v).indexOf('unset')) {
            return `<span class="badge badge-light">${this.colMap[k] || k}</span> <span class="badge badge-warning">UNSET</span>`;
          } else if (~Object.keys(v).indexOf('updated_from')) {
            return `<span class="badge badge-light">${this.colMap[k] || k}</span> <span class="badge badge-secondary">변경됨</span> '${html(v.updated_from)}' ⇒ '${html(v.updated_to)}'`;
          } else if (~Object.keys(v).indexOf('updated_to')) {
            return `<span class="badge badge-light">${this.colMap[k] || k}</span> <span class="badge badge-secondary">업데이트됨</span> '?' ⇒ '${html(v.updated_to)}'`;
          }
          // return `<span class="badge badge-light">${this.colMap[k] || k}</span> ${JSON.stringify(v)}`;
          return `<span class="badge badge-light">${this.colMap[k] || k}</span><br/><div class="pl-3">${this.formatDiff(v)}</div>`;
        }
        if (utils.typeOf(v) === 'array') { // array of some
          return `<span class="badge badge-light">${this.colMap[k] || k}</span><br/><div class="pl-3">${v.map(e => this.formatDiff(e)).join('<br/>')}</div>`;
        }
        if (v) {
          return `<span class="badge badge-light">${this.colMap[k] || k}</span> ${JSON.stringify(v)}`;
        }
      }).filter(e => e).join('<br/>');
    },
    formatJson(j, fieldMap) {
      if (j == null) return '';
      const tr = [];
      const hideKeys = ('_diff,_diff_history,price_table,images,original_img_urls,replaced_img_urls,uploaded_images,' +
        'confirmed,mapped,src,_src,_org,cf,mp,published').split(',');
      Object.keys(j).forEach(key => {
        let field = (fieldMap ? fieldMap[key] : null) || this.$FM[''][key];
        let value = j[key];
        let valueType = typeof j[key];
        let collapse = '';
        const defaultHide = hideKeys.includes(key) && valueType === 'object';
        const valueStyle = `display: ${defaultHide ? 'none' : 'block'}`;

        if (value == null) {
          value = 'null';
          valueType = 'null';
        } else if (valueType === 'object' && value.getTime || valueType === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/)) {
          if (valueType === 'string' && value.match(/^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}.\d{3}Z/)) value = value.split('Z')[0] + 'Z';
          value = this.$moment(value).format('YYYY-MM-DD HH:mm:ss');
          valueType = 'date';
        } else if (valueType === 'object') {
          collapse = `<i class="objectCaret fa fa-caret-square-o-${defaultHide ? 'up defaultHide' : 'down'} ml-1" onclick="toggleTd()"></i>`;
          value = this.formatJson(value, fieldMap || this.$FM[key]);
        } else if (valueType === 'string' && value.match(/^(http(s)?:\/\/)([a-z0-9\w]+\.*)+[a-z0-9]{2,4}/i)) { // 도메인으로 시작한다면 링크화
          value = `<a href="${value}" target="_blank">${value}</a>`;
        } else if (key === '_id' && valueType === 'string' && value.match(/^[0-9a-f]{24}$/i)) { // mongodb _id 라면 시간 추가
          value = value + ` <span style="color: #888888">(${this.$utils.kstDT(Types.ObjectId(value).getTimestamp())})</span>`;
        } else {
          value = value.toString().escapeHtml();
        }

        const keyStyle = {object: 'background-color: #d9eaff'}[valueType] || '';

        if (field) {
          tr.push(`<tr><th class="${field.class}" style="${keyStyle}">${key}${field.name ? '&nbsp;[' + field.name.escapeHtml() + ']' : ''}${field.desc
            ? `<i class="fa fa-question-circle ml-1" title="${field.desc}"></i>` : ''}${collapse}</th><td class="${valueType}">
                <div style="${valueStyle}">${value}</div><button style="display: ${!defaultHide ? 'none' : 'block'}" onclick="toggleTd()">more</button></td></tr>`);
        } else {
          tr.push(`<tr><th style="${keyStyle}">${key.escapeHtml()}${collapse}</th><td class="${valueType}"><div style="${valueStyle}">${value}</div>
              <button style="display: ${!defaultHide ? 'none' : 'block'}" onclick="toggleTd()">more</button></td></tr>`);
        }
      });
      return '<table class="json">' + tr.join('') + '</table>';
    },
    async optionXlsx(diff) {
      if (!diff) return alert('수정이력이 없습니다');
      const optDiff = diff.filter(e => e.options).reverse();
      if (!optDiff.length) return alert('options 의 변화가 없습니다');

      const cStr = v => v == null ? '' : `생성됨: ${v}`;
      const dStr = v => v == null ? '' : `제거됨: ${v}`;
      const cudStr = v => v == null ? '' : v.created != null ? `생성됨: ${v.created}` : v.updated_from != null || v.updated_to != null ? `${v.updated_from} => ${v.updated_to}` : v.deleted != null ? `제거됨: ${v.deleted}` : v;
      const rows = optDiff.map(e => {
        if (utils.typeOf(e.options) === 'object') {
          console.log(e.options);
          return [];
        }
        return e.options.map(opt => Object.assign(opt.created ? {
          Size: opt.created.Size,
          goods_price: cStr(opt.created.goods_price),
          goods_consumer: cStr(opt.created.goods_consumer),
          stock: cStr(opt.created.stock),
          shop_stock: cStr(opt.created.shop_stock),
          order_stock: cStr(opt.created.order_stock),
        } : opt.deleted ? {
          Size: opt.deleted.Size,
          goods_price: dStr(opt.deleted.goods_price),
          goods_consumer: dStr(opt.deleted.goods_consumer),
          stock: dStr(opt.deleted.stock),
          shop_stock: dStr(opt.deleted.shop_stock),
          order_stock: dStr(opt.deleted.order_stock),
        } : {
          Size: opt.Size,
          goods_price: cudStr(opt.goods_price),
          goods_consumer: cudStr(opt.goods_consumer),
          stock: cudStr(opt.stock),
          shop_stock: cudStr(opt.shop_stock),
          order_stock: cudStr(opt.order_stock),
        }, {
          dt: e._dt,
          at: e._at,
          name: e._name,
          ip: e._ip,
        }));
      }).reduce((a, b) => a.concat(b), []);

      down(rows, null, 'Size,stock,goods_price,goods_consumer,shop_stock,order_stock,dt,at,name,ip'.split(','), `OptionsDiff_${utils.dt()}`, 'xlsx');
    },
    async rollbackDiff(diff, item) {
      if (confirm("정말 되돌리시겠습니까?")) {
        let resJson = await postJson('/dev/diffRollback', {
          diff: JSON.stringify(diff),
          item: item,
          db: this.db,
          collection: this.collection
        });
        if (!resJson) {
          confirm("rollback에 실패하였습니다!");
          return;
        }
        this.syncItem(resJson);
      }
    },
    filterDelField(item) {
      const obj = {};
      Object.keys(item).filter(e => e.startsWith('_del')).forEach(k => {
        obj[k] = item[k];
      });
      return obj;
    },
    toggleAll(mode, event) {
      // const table = event.target.parentElement.nextSibling || event.target.parentElement;
      console.log(mode, event);
      const parent = this.$refs.json;
      if (mode === 'show') {
        for (const i of parent.querySelectorAll('.objectCaret.fa-caret-square-o-up')) {
          i.click();
        }
      } else if (mode === 'default') {
        for (const i of parent.querySelectorAll('.objectCaret.fa-caret-square-o-up')) {
          i.click();
        }
        for (const i of parent.querySelectorAll('.objectCaret.defaultHide')) {
          i.click();
        }
      } else if (mode === 'hide') {
        for (const i of parent.querySelectorAll('.objectCaret.fa-caret-square-o-down')) {
          i.click();
        }
      }
    }
  },
}
window.toggleTd = function () {
  const td = event.target.parentElement.nextSibling || event.target.parentElement;
  const th = td.previousSibling;
  const collapse = th.lastElementChild;
  const valueObject = td.firstElementChild;
  const btnObject = td.lastElementChild;
  if (valueObject.style.display === 'none') {
    collapse.classList.remove('fa-caret-square-o-up');
    collapse.classList.add('fa-caret-square-o-down');
    valueObject.style.display = 'block';
    btnObject.style.display = 'none';
  } else {
    collapse.classList.remove('fa-caret-square-o-down');
    collapse.classList.add('fa-caret-square-o-up');
    valueObject.style.display = 'none';
    btnObject.style.display = 'block';
  }
};
</script>

<style>
div.modal-base>pre {
  word-break: break-all;
  white-space: pre-wrap;
}

table.json {
  width: 100%;
  margin: 5px 0;
}
table.json th {
  font-size: 12px;
  border-collapse: collapse;
  border: 1px solid #ccc;
  background-color: #eaf0ff;
  padding: 0 5px;
  width: 20px;
  white-space: nowrap;
}
table.json th.underscore {
  color: #888888;
}

table.json td {
  border-collapse: collapse;
  border: 1px solid #eee;
  padding: 0 5px;
  word-break: break-all;
}
table.json td.number {
  color: #00b000;
}
table.json td.date {
  color: #a080a0;
}
table.json td.null {
  color: #dddddd;
}
table.json button {
  border-radius: 5px;
  border: 1px solid #eee;
  width: 50px;
  height: 20px;
  line-height: 14px;
  background-color: #eee;
  padding-bottom: 4px;
  margin: 3px 0;
}
</style>
