/**
 * 클라이언트 버전 갱신을 app.js Hash를 통해 명확하게 하는 방법
 * https://medium.com/@codemonk/automatic-reload-of-client-after-deploying-in-vue-js-91c120f85f0e
 */
export const refreshPageMixin = {
  data() {
    return {
      currentHash: '{{POST_BUILD_HASH_HERE}}',
    }
  },
  created() {
    window.newHash = window.currentHash = this.currentHash;
  },
  sockets: {
    /**
     * hash를 받아서 현재 클라이언트의 hash 와 비교하고 다를 경우 새로고침 팝업을 띄운다.
     */
    version({version, hash}) {
      window.newHash = hash;
      // console.log('version', version, hash);
      if (!window.webpackHotUpdate && this.currentHash !== hash) {
        this.$bvToast.hide('version');
        this.$bvToast.toast(`여기를 누르시면 새로고침을 합니다.`, {
          id: 'version',
          title: 'HUB의 새로운 버전이 배포되었습니다',
          href: 'javascript:location.reload()',
          // autoHideDelay: 4000
        });
        if (!window.globalReloadTimeout) {
          window.globalReloadTimeout = setTimeout(() => {
              // 새벽 5시 30분 자동 리로딩
              location.reload();
            }, ((20.5 - (new Date / (3600 * 1000) % 24) + 24) % 24) * 3600 * 1000
          );
        }
      }
    },
  },
};
