import Handsontable from "handsontable";
import * as utils from '@/shared/utils';
import {USED_GRADE} from 'balaan_constants';

export const htImgRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  if (td && utils.typeOf(value) === 'array') {
    td.className = 'htCenter htMiddle pointer';
    if (value.length === 0) {
      td.innerText = 'empty';
      return td;
    }
    Handsontable.dom.empty(td);
    value.slice(0, 1).forEach(v=>{
      let img = document.createElement('IMG');
      let href = img.src = v;
      if (typeof v === 'object') {
        img.src = v.src;
        href = v.href;
      }
      img.height = 60;
      Handsontable.dom.addEvent(img, 'mousedown', function (event) {
        event.preventDefault();
      });
      Handsontable.dom.addEvent(img, 'click', function (event) {
        utils.open(href);
      });
      td.appendChild(img);
    });
  } else {
    Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);
  }
  return td;
};

export function checkModifiedRenderer(instance, td, row, col, prop, value, cellProperties) {
  // Optionally include `BaseRenderer` which is responsible for adding/removing CSS classes to/from the table cells.
  Handsontable.renderers.TextRenderer.apply(this, arguments);
  let _changed = instance.getDataAtRowProp(row, '_changed');
  const escaped = Handsontable.helper.stringify(value);
  if (td && _changed[prop]) {
    td.className += ' bg-changed';
    // td.innerText = escaped;
    // instance.setCellMeta(row, col, 'className', 'htCenter htMiddle bg-changed');
  } else {
    // Handsontable.renderers.TextRenderer(instance, td, row, col, prop, value, cellProperties);
  }
  // td.innerText = escaped;
  return td;
}

export function checkModifiedNumericRenderer(instance, td, row, col, prop, value, cellProperties) {
  // Optionally include `BaseRenderer` which is responsible for adding/removing CSS classes to/from the table cells.
  Handsontable.renderers.NumericRenderer.apply(this, arguments);
  let _changed = instance.getDataAtRowProp(row, '_changed');
  // const escaped = Handsontable.helper.stringify(utils.comma(value));
  if (td && _changed[prop]) {
    td.className += ' bg-changed';
  }
  return td;
}

export function checkModifiedDropdownRenderer(instance, td, row, col, prop, value, cellProperties) {
  // Optionally include `BaseRenderer` which is responsible for adding/removing CSS classes to/from the table cells.
  Handsontable.renderers.DropdownRenderer.apply(this, arguments);
  let _changed = instance.getDataAtRowProp(row, '_changed');
  if (td && _changed[prop]) {
    td.className += ' bg-changed';
  }
  return td;
}

export const afterChangeGen = (id, $ref) => {
  function afterChange (changes) {
    changes && changes.forEach(([row, prop, oldValue, newValue]) => {
      if (~['selected','_modified','img'].indexOf(prop)) return;
      let cell = this.$refs[$ref].hotInstance.getCell(row, this.$refs[$ref].hotInstance.propToCol(prop));
      let item = this.items[id][row];
      if (item._org[prop] !== newValue && !(item._org[prop] == null && newValue === '')) {
        item._changed[prop] = true;
        // this.$refs.hotTableProcessing.hotInstance.setCellMeta(row, this.$refs.hotTableProcessing.hotInstance.propToCol(prop), 'className', 'htCenter htMiddle bg-changed'); // 바로는 안됨
        if (cell) cell.className = 'htCenter htMiddle bg-changed';
      } else {
        delete item._changed[prop];
        item[prop] = item._org[prop];
        if (cell) cell.className = 'htCenter htMiddle';
      }
      // console.log(row, prop, oldValue, newValue, 'modified', !Object.values(item._changed).every(e=>!e));
      item._modified = !Object.values(item._changed).every(e=>!e);
      this.changed[id] = this.items[id].filter(e => e._modified && !Object.values(e._changed).every(e => !e)).length;
    });
  }
  return afterChange;
};

export const commaRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, typeof value === 'number' ? utils.rnc(value) : value, cellProperties]);

  // asis
  /*
  td.className = cellProperties.className;
  if (td && utils.typeOf(value) === 'number') {
    // td.className = 'htRight htMiddle htDimmed';
    td.innerText = utils.rnc(value);
  } else if (td) {
    // td.className = 'htCenter htMiddle htDimmed';
    td.innerText = value;
  }
  return td;
  */
  // if (utils.typeOf(value) === 'number') {
  //   const span = document.createElement('SPAN');
  //   span.innerText = utils.rnc(value);
  //   Handsontable.dom.empty(td);
  //   td.appendChild(span);
  // } else {
  //   Handsontable.renderers.TextRenderer.apply(this, arguments);
  // }
  // return td;
};

export const percentRendererFactory = (digit = 1, multiple = 100, comma = false) => {
  return function (instance, td, row, col, prop, value, cellProperties) {
    value = typeof value === 'number' ?
      (comma ? (+value * multiple).toFixed(digit).comma() + ' %' : (+value * multiple).toFixed(digit) + ' %') :
      value;
    Handsontable.renderers.TextRenderer.apply(this, [instance, td, row, col, prop, value, cellProperties]);

    // asis
    /*
    td.className = cellProperties.className;
    Handsontable.dom.empty(td);
    if (value == null) return td;

    const span = document.createElement('SPAN');
    span.innerText = (+value * multiple).toFixed(digit) + ' %';
    td.appendChild(span);

    return td;
    */
  }
}
export const percentRenderer = percentRendererFactory(2);

export const nullishRenderer = (instance, td, row, col, prop, value, cellProperties) => {
  td.className = cellProperties.className;
  if (td && value == null) {
    td.className += ' grey-c4';
    td.innerText = `(${value})`;
  } else if (td) {
    td.innerText = value;
  }
  return td;
};

Handsontable.renderers.registerRenderer('image', htImgRenderer);
Handsontable.renderers.registerRenderer('modified', checkModifiedRenderer);
Handsontable.renderers.registerRenderer('modifiedNumeric', checkModifiedNumericRenderer);
Handsontable.renderers.registerRenderer('modifiedDropdown', checkModifiedDropdownRenderer);
Handsontable.renderers.registerRenderer('comma', commaRenderer);
Handsontable.renderers.registerRenderer('percent', percentRenderer);
Handsontable.renderers.registerRenderer('percent:0:100', percentRendererFactory(0, 100));
Handsontable.renderers.registerRenderer('percent:1:100', percentRendererFactory(1, 100));
Handsontable.renderers.registerRenderer('percent:2:100', percentRendererFactory(2, 100));
Handsontable.renderers.registerRenderer('percent:0:1', percentRendererFactory(0, 1));
Handsontable.renderers.registerRenderer('percent:1:1', percentRendererFactory(1, 1));
Handsontable.renderers.registerRenderer('percent:2:1', percentRendererFactory(2, 1));
Handsontable.renderers.registerRenderer('percent:0:100:comma', percentRendererFactory(0, 100, true));
Handsontable.renderers.registerRenderer('percent:1:100:comma', percentRendererFactory(1, 100, true));
Handsontable.renderers.registerRenderer('percent:2:100:comma', percentRendererFactory(2, 100, true));
Handsontable.renderers.registerRenderer('percent:0:1:comma', percentRendererFactory(0, 1, true));
Handsontable.renderers.registerRenderer('percent:1:1:comma', percentRendererFactory(1, 1, true));
Handsontable.renderers.registerRenderer('percent:2:1:comma', percentRendererFactory(2, 1, true));
Handsontable.renderers.registerRenderer('nullish', nullishRenderer);

const usedGrades = USED_GRADE.map(e => e.value);
export const cfColumns = [
  {data: 'delivery_type', name: '배송유형', width: 80, editor: 'select', selectOptions: ['국내', '해외'], source: ['국내', '해외'], processing: true, registered: true},
  {data: 'oneday_delivery', name: '오늘도착', width: 80, editor: 'select', selectOptions: ['Y', 'N'], source: ['Y', 'N'], processing: false, registered: true},
  {data: 'goodsType', name: '상품유형', width: 80, editor: 'select', selectOptions: ['새상품', '빈티지'], source: ['새상품', '빈티지'], processing: true, registered: true},
  {data: 'usedGrade', name: '빈티지등급', width: 80, editor: 'select', selectOptions: usedGrades, source: usedGrades, processing: true, registered: true},
  {data: 'sku_id', name: 'SKU ID', width: 150, renderer: 'modified', processing: true, registered: true},
  {data: 'matched_sku_id', name: '통합 SKU ID', width: 150, renderer: 'modified', processing: true, registered: true},
  {data: 'category', name: '카테고리', width: 100, renderer: 'modified', processing: false, registered: false},
  {data: 'brand_no', name: '브랜드', width: 70, renderer: 'modified', processing: false, registered: false},
  {data: 'season', name: '시즌', width: 70, renderer: 'modified', processing: true, registered: true},
  {data: 'color', name: '컬러', width: 100, renderer: 'modified', processing: true, registered: true},
  {data: 'origin', name: '원산지', width: 100, renderer: 'modified', processing: true, registered: true},
  {data: 'description', name: '상품상세', width: 200, renderer: 'modified', className: 'truncate', processing: false, registered: true},
  {data: 'composition', name: '소재', width: 200, renderer: 'modified', processing: true, registered: true},
  {data: 'measurements', name: 'measurements', width: 150, renderer: 'modified', processing: true, registered: true},
  {data: 'modelsize', name: 'modelsize', width: 150, renderer: 'modified', processing: true, registered: true},
  {data: 'fta', name: 'fta(y/n)', width: 60, editor: 'select', selectOptions: ['Y', 'N'], source: ['Y', 'N'], renderer: 'modified', processing: false, registered: false},
  {data: 'seller_name', name: '위탁판매자', width: 80, renderer: 'modified', processing: true, registered: false, modify: false},
  {data: 'md_name', name: '담당MD', width: 80, renderer: 'modified', processing: true, registered: true},
];
