<template>
  <div v-bind="$attrs" :style="{height: $attrs.height || '300px'}">
    <div style="overflow: hidden" :style="wrapperStyle">
      <hot-table ref="htb" v-on="$listeners" :settings="settings"></hot-table>
    </div>
    <div v-if="controls && !sample" ref="controls" class="clearfix mt-1">
      <b-form v-if="enableAddRows" class="pull-left" inline>
        <b-input-group>
          <b-form-input v-model.number="addRowNum" size="sm" class="w-50px text-center"></b-form-input>
          <b-input-group-append>
            <b-button variant="light" size="sm" @click="addRows(addRowNum)">행 추가</b-button>
            <template v-for="n in addRowsPreset">
              <b-button variant="secondary" size="sm" @click="addRows(n)" :key="n">+{{ n }}</b-button>
            </template>
          </b-input-group-append>
        </b-input-group>

        <b-button v-if="enableRemoveRows" class="ml-1" variant="warning" size="sm" @click="removeRows('empty')" title="값이 모두 비어있는 행을 정리합니다">행 정리</b-button>
      </b-form>
      <div v-if="enableDownRows" class="pull-right">
        <b-button v-if="enableShowRows" class="ml-1" variant="info" size="sm" @click="showRows()">Data 보기</b-button>
        <b-button class="ml-1" variant="success" size="sm" @click="downRows()">Xlsx Down</b-button>
      </div>
    </div>
  </div>
</template>

<script>
import Handsontable from 'handsontable';
import {down} from '@/shared/impexp'

const sampleData = [
  {num: 12345, comma: 12345, bool: true, str: 'string', some_yn: 'y', html: '<b>bold</b>', btn: 'Btn 1'},
  {num: 2345678, comma: 234567800, bool: false, str: 'string', some_yn: 'n', html: '<i class="fa fa-smile-o"></i>', btn: 'Btn 2'}
];

export default {
  name: 'HotTableBase',
  model: {prop: 'value', event: 'change'},
  props: {
    value: Array,
    config: {type: Object, default() { return {}; }},
    controls: {type: Boolean, default: false},
    enableAddRows: {type: Boolean, default: true},
    addRowsPreset: {type: Array, default() { return [5, 10, 50, 100, 1000]; }},
    enableRemoveRows: {type: Boolean, default: true},
    enableShowRows: {type: Boolean, default: true},
    enableDownRows: {type: Boolean, default: true},
    filePrefix: {type: String, default: 'GridData'}, // xlsx 등을 받을 때의 파일명
    sample: {type: Boolean, default: false},
  },
  data() {
    return {
      addRowNum: 1,
      wrapperStyle: {height: '100%'},
      defaultSettings: {
        columns: this.sample ? [
          {name: '숫자', data: 'num', type: 'numeric'},
          {name: '콤마', data: 'num', type: 'numeric', renderer: 'comma'},
          {name: '% (x100, .0, comma)', data: 'num', type: 'numeric', renderer: 'percent:0:100:comma', readOnly: false},
          {name: '% (x100, .1)', data: 'num', type: 'numeric', renderer: 'percent:1:100'},
          {name: '% (x1, .0)', data: 'num', type: 'numeric', renderer: 'percent:0:1'},
          {name: '% (x1, .2)', data: 'num', type: 'numeric', renderer: 'percent:2:1'},
          {name: 'Select', data: 'some_yn', width: 80, editor: 'select', selectOptions: {y: 'Yes', n: 'No'}, source: ['y', 'n'], readOnly: false},
          {name: 'Autocomplete', data: 'some_yn', width: 80, editor: 'autocomplete', source: ['y', 'n'], readOnly: false},
          {name: 'Dropdown', data: 'some_yn', width: 80, editor: 'dropdown', source: ['y', 'n'], readOnly: false},
          {name: 'Boolean', data: 'bool'},
          {name: '문자', data: 'str'},
          {name: 'HTML', data: 'html', renderer: 'html'},
          {
            name: '버튼', data: 'btn', width: 60, readOnly: true,
            renderer: (instance, td, row, col, prop, value, cellProperties) => {
              // console.log(this.listAllProperties(cellProperties), cellProperties.className);
              // console.log(td.className, cellProperties.className, td);
              td.className = cellProperties.className;
              Handsontable.dom.empty(td);
              const onclick = () => this.$modal.show({title: 'JSON', item: sampleData[cellProperties.row], type: 'json'});

              // A - html 교체로 생성
              td.innerHTML = `<button class="btn btn-light btn-sm">${value}</button>`;
              td.firstChild.onclick = onclick;

              // B - element 생성 후 추가
              // const btn = document.createElement('BUTTON');
              // btn.className = 'btn btn-light btn-sm';
              // btn.innerText = '보기';
              // btn.onclick = onclick;
              // td.appendChild(btn);

              return td;
            }
          },
        ] : null,
        /*
           columns sample
        columns: [

        ],
        */
        // columns: (index) => {}, // index 를 받아서 해당 순번의 {data: 'id'} 등의 구성을 전달
        // data: [],
        // cells: (row, col, prop) => {},
        data: this.sample ? sampleData : [],
        className: 'htMiddle htCenter', // cell 별 class
        autoWrapCol: false, // true: 셀의 끝 도달시 처음으로 이동
        autoWrapRow: false, // true: 셀의 끝 도달시 처음으로 이동
        columnSorting: true,
        // dropdownMenu: true, // colHeader 의 추가 기능 버튼
        // filters: true, // colHeader 의 추가 기능 중 필터링
        manualColumnResize: false,
        // rowHeaders: true,
        // colHeaders: true,
        // colHeaders: (index) => {},
        colHeaders: (index) => {
          const {data, name} = this.settings.columns[index];
          return name || data;
        },
        // nestedHeaders: [ // nestedHeaders 를 적용한다면 데이터가 없을 때 header 가 보이지 않는다.
        //   ['A', { label: 'B', colspan: 8 }, 'C'],
        //   ['D', { label: 'E', colspan: 4 }, { label: 'F', colspan: 4 }, 'G'],
        //   ['H', { label: 'I', colspan: 2 }, { label: 'J', colspan: 2 }, { label: 'K', colspan: 2 }, { label: 'L', colspan: 2 }, 'M'],
        //   ['N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'W']
        // ],
        // fixedColumnsLeft: 3,
        // colWidths: 100,
        // height: '300px',
        // rowHeights: 60,
        // startCols: 8,
        // startRows: 25,
        stretchH: "all",
        width: '100%',
        readOnly: true,
        // afterChange: (changes) => {
        //   this.$emit('afterChange', changes);
        // },
        // afterChange(changes) {
        //   changes && changes.forEach(([row, prop, oldValue, newValue]) => {
        //     if (~['selected', '_modified', 'img'].indexOf(prop)) return;
        //
        //     console.log(this);
        //     const cell = this.hotInstance.getCell(row, this.hotInstance.propToCol(prop));
        //     const item = this.data[row];
        //     if (!item._org || !item._changed) return;
        //
        //     if (item._org[prop] !== newValue && !(item._org[prop] == null && newValue === '')) {
        //       item._changed[prop] = true;
        //       // this.$refs.hotTableProcessing.hotInstance.setCellMeta(row, this.$refs.hotTableProcessing.hotInstance.propToCol(prop), 'className', 'htCenter htMiddle bg-changed'); // 바로는 안됨
        //       if (cell) cell.className = 'htCenter htMiddle bg-changed';
        //     } else {
        //       delete item._changed[prop];
        //       item[prop] = item._org[prop];
        //       if (cell) cell.className = 'htCenter htMiddle';
        //     }
        //     // console.log(row, prop, oldValue, newValue, 'modified', !Object.values(item._changed).every(e=>!e));
        //     item._modified = !Object.values(item._changed).every(e => !e);
        //   });
        // },
        // afterScrollVertically: () => {},
        // afterSelection: (row, column, row2, column2, selectionLayerLevel) => {},
        // afterSelectionEnd: (row, column, row2, column2, selectionLayerLevel) => {},
        // afterLoadData(sourceData, initialLoad, source) { console.log(sourceData, initialLoad, source); },
        licenseKey: 'non-commercial-and-evaluation',
      },
      settings: {},
    }
  },
  created() {
    Object.assign(this.settings, this.defaultSettings, this.config.settings);
    this.settings.data = this.value || this.settings.data;
    if (!this.settings.columns && this.config.fields) {
      this.settings.columns = this.config.fields.map(this.fieldToColumn);
    }
    if (!this.settings.columns && this.config.keys) { // 다음 순으로 keys 가 있다면 key 로 타입 없이
      this.settings.columns = this.config.keys.map(e => ({data: e}));
    }
    if (!this.settings.columns) { // 기본 컬럼 설정이 없다면 data 로부터 생성
      // this.settings.columns = [];
    }
    // console.log('settings', this.config.fields, this.settings);

    // modified 계열의 renderer 를 사용한다면 관련 필드를 미리 준비해둔다
    this.settings.data.forEach(e => {
      e._org = this.$utils.clone(e);
      e._changed = {};
    });
  },
  mounted() {
    // 화면 사이즈가 메뉴바의 애니메이션 등에 의해 변경될 때, 렌더링이 어긋나는 문제가 있다.
    // nextTick, 0, 1, 10 등은 parent 의 size 가 바뀔 때 width 가 잘 반영되지 않을 때가 있다. 반복적으로 설정한다.
    setTimeout(this.render, 0);
    setTimeout(this.render, 100);
    setTimeout(this.render, 1000);
  },
  watch: {
    config: {
      deep: true,
      handler(v) {
        // console.log('htb watch', v, this.config, v === this.config, this.settings);
        Object.assign(this.settings, this.config.settings);
        this.settings.data = this.value || this.settings.data;
        this.$refs.htb.hotInstance.updateSettings(this.settings);
      },
    },
    value: {
      deep: true,
      handler(v) {
        // config.fields 가 없고 config.settings.columns 가 없다면 key 를 중첩하여 확보 후 지정, config.labelMap 을 활용
        if (!this.settings.columns) {
          const keys = this.extractKeys(v);
          const labelMap = this.config.labelMap || {};
          this.settings.columns = keys.map(k => ({data: k, name: labelMap[k] || k}));
          this.$refs.htb.hotInstance.updateSettings(this.settings);
          // console.log('make columns', this.config, this.settings);
        }
        this.$refs.htb.hotInstance.loadData(v);
        // console.log('value watch', this.config, this.settings);
      }
    }
  },
  computed: {
    data: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
    hotInstance: {
      get() {
        return this.$refs.htb.hotInstance;
      },
      set() {
      }
    },
  },
  methods: {
    fieldToColumn(f) {
      const col = {data: f.key, allowEmpty: true};
      // type 에 따라 기본 className 과 renderer 설정
      if (f.type === 'number') {
        // col.className = 'htRight';
        col.type = 'numeric';
        col.renderer = 'comma';
      // } else if (f.type === 'string') {
      // } else if (f.type === 'boolean') {
      // } else if (f.type === 'object') {
      }
      'name,width,className,renderer,editor,readOnly,numericFormat,allowEmpty'.split(',').forEach(k => {
        if (f[k] !== undefined) col[k] = f[k];
      });
      return col;
    },
    extractKeys(data) {
      const dupRow = {};
      data.forEach(row => Object.assign(dupRow, row));
      return Object.keys(dupRow);
    },
    addRows(n) {
      const instance = this.hotInstance;
      for (let i = 0; i < n; i++) {
        this.data.push({});
      }
      instance.loadData(this.value);
      instance.render();
    },
    showRows() {
      this.$modal.show({title: '데이터', item: this.value, type: 'json'});
    },
    removeRows(type) {
      const instance = this.hotInstance;
      if (type === 'empty') {
        this.data = this.data.filter(e => Object.values(e).filter(e => e != null).length > 0);
        this.$alertTop(`빈 행을 정리했습니다`, {variants: 'warning'});
      } else if (type === 'not_done') {
        // page.goods = page.goods.filter(e => e.goods_no && (e.final_sale_price || e.price || e.consumer || e.rebate) != null);
        // this.$alertTop(`완전하지 않은 행을 정리했습니다`, {variants: 'danger'});
      }
      instance.loadData(this.value);
      instance.render();
    },
    async downRows() {
      const fields = this.settings.columns.map(e => e.data);
      down(this.value, fields, fields, `${this.filePrefix}_${this.$utils.kstDT()}`, 'xlsx');
    },
    refresh() {
      this.$refs.htb.hotInstance.updateSettings(this.settings);
      this.$refs.htb.hotInstance.loadData(this.value);
    },
    render() {
      if (this.controls) {
        this.$nextTick(() => {
          this.wrapperStyle.height = `calc(100% - 0.25rem - ${this.$refs.controls.offsetHeight + 1}px)`;
        });
      }
      this.$refs.htb.hotInstance.updateSettings({width: this.settings.width || '100%'});
      this.$refs.htb.hotInstance.render();
    },
    listAllProperties(o) {
      let result = [];
      for (let objectToInspect = o; objectToInspect !== null; objectToInspect = Object.getPrototypeOf(objectToInspect)) {
        result = result.concat(Object.getOwnPropertyNames(objectToInspect));
      }
      return result;
    },
  },
}
</script>
