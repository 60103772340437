<template>
  <div class="">
    <b-input-group>
      <masked-input
        type="text"
        name="date"
        class="form-control w-date"
        v-model="date"
        :mask="[/\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, '-', /\d/, /\d/]"
        :guide="true"
        placeholderChar="_"
        :showMask="true"
        :keepCharPositions="true"
        @keypress.enter.prevent.stop="onEnter"
        :pipe="autoCorrectedDatePipe()">
      </masked-input>
      <b-input-group-append v-if="calendar">
        <date-picker v-model="datePick" style="z-index: 999">
          <b-button variant="primary"><i class="fa fa-calendar"></i></b-button>
        </date-picker>
      </b-input-group-append>
    </b-input-group>
  </div>
</template>

<script>
import * as moment from 'moment-timezone';
import MaskedInput from 'vue-text-mask'
import createAutoCorrectedDatePipe from "text-mask-addons/dist/createAutoCorrectedDatePipe";
import {setupCalendar, DatePicker} from 'v-calendar'
import 'v-calendar/lib/v-calendar.min.css'
setupCalendar({
  firstDayOfWeek: 1
});

export default {
  name: "DateInput",
  components: {MaskedInput, DatePicker},
  model: {prop: 'value', event: 'change'},
  props: {
    value: String,
    calendar: {type: Boolean, default: true},
  },
  data() {
    return {
    }
  },
  async created() {

  },
  computed:{
    date: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
    datePick: {
      get() {
        if ((this.value || '').indexOf('_') === -1) {
          return moment(this.value).toDate()
        }
        return moment().toDate();
      },
      set(v) {
        this.date = moment(v).format('YYYY-MM-DD')
      }
    },
  },
  methods: {
    onEnter() {
      this.$emit('enter');
    },
    autoCorrectedDatePipe() {
      return createAutoCorrectedDatePipe('yyyy-mm-dd');
    },
  },
}
</script>
