const GlobalMixin = {
  data () {
    return { // 여기에 선언하면 component 의 data() 에서 사용할 수 없다.
      port: +(localStorage.getItem('port') || 3030),
    }
  },
  async created () {
    const host = location.hostname;
    const env = this.ENV = host.startsWith('hub-dev') || this.port !== 3030 ? 'dev' : host.startsWith('hub-stage') ? 'stage' : 'prod';
    this.NODE_ENV = process.env.NODE_ENV;
    this.ENV_VARIANT = env === 'dev' ? 'warning' : env === 'stage' ? 'success' : 'primary';
    this.IS_LOCAL = host === 'localhost' || host.startsWith('192.168');
    this.IS_DEV = env === 'dev';
    this.MALL_URL = process.env.VUE_APP_MALL_URL;
    this.ADMIN_URL =  process.env.VUE_APP_ADMIN_URL;
    this.IS_SERVE = !!window.webpackHotUpdate;
    this.BALOG_URL =  process.env.VUE_APP_BALOG_URL;
    this.PRIVATE = this.$S.user ? !!this.$S.user.private : false;
    this.S3_CDN= process.env.VUE_APP_S3_CDN;
    this.CATALOG_API_URL =  process.env.VUE_APP_CATALOG_API_URL;
  },
  async mounted() {
    if (this.$options.title) {
      this.$log('pageview');
      // console.log(this.$route.path, this.$route.name, this.$options.name, this.$options.title, this.$S.user.id,
      //   this.$route.matched.slice(-1)[0].path.replace(/\/:.*/, ''), this.$route);
    }
  },
  async beforeDestroy () {
  },
  methods: {
    $log(type, data) {
      const user = this.$S.user || {id: '', group: ''};
      const user_id = user.id;
      const group = user.group;
      const path = this.$route.matched.slice(-1)[0].path.replace(/\/:.*/, '');

      if (type === 'pageview') {
        // this.$gtag.config({
        //   user_id: this.user && this.user.id || '',
        //   shop_id: this.user && this.user.shop && this.user.shop.shop_id || -1
        // })
        // this.$gtag.set({
        //   shop_id: this.user && this.user.shop && this.user.shop.shop_id || -1
        // })
        this.$gtag.pageview({
          user_id, group,
          page_path: path,
        });

        if (location.host === 'hub.balaan.io' && !!this.BALOG_URL) { // 실 데이터만
          const url = new URL(this.BALOG_URL);
          url.searchParams.append('index', 'log-hub-page');
          url.searchParams.append('type', 'pageview');
          url.searchParams.append('path', path);
          url.searchParams.append('title', this.$options.title || '');
          url.searchParams.append('user_id', user_id);
          url.searchParams.append('group', group);

          navigator.sendBeacon(url.toString());
        }
      } else if (type === 'event') {
        this.$gtag.event(`${path}:${data}`, {
          user_id, group,
          non_interaction: true
        });

        if (location.host === 'hub.balaan.io' && !!this.BALOG_URL) { // 실 데이터만
          const url = new URL(this.BALOG_URL);
          url.searchParams.append('index', 'log-hub-event');
          url.searchParams.append('type', 'event');
          url.searchParams.append('action', `${path}:${data}`);
          url.searchParams.append('user_id', user_id);
          url.searchParams.append('group', group);

          navigator.sendBeacon(url.toString());
        }
      }
    }
  }
}

export default GlobalMixin
