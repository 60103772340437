import Vue from 'vue'
import Vuex from 'vuex'
import getters from './getters'

Vue.use(Vuex)

// https://webpack.js.org/guides/dependency-management/#requirecontext
// const modulesFiles = require.context('./modules', true, /\.js$/)

// you do not need `import app from './modules/app'`
// it will auto require all vuex module from modules file
// const modules = modulesFiles.keys().reduce((modules, modulePath) => {
//   // set './app.js' => 'app'
//   const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
//   const value = modulesFiles(modulePath)
//   modules[moduleName] = value.default
//   return modules
// }, {})

let user = localStorage.getItem('user');
if (user) user = JSON.parse(user);
else user = {group: []};

const store = new Vuex.Store({
  state: {
    // _getJsonCache: {},
    // _postJsonCache: {},
    user, // 유저 정보
    page: {}, // 페이지별 폼 데이터 등
    meta: {}, // 메타데이터 캐시
    meta_checksum: {}, // 메타데이터 체크섬
    m: { // 새로운 방식의 meta - 화면 최초 로딩시 전체로딩, 이후 갱신은 socket 이벤트를 받아 개별갱신
      shop: {list: [], map: {}, prm: null},
      brand: {list: [], map: {}, prm: null},
      category: {list: [], map: {}, prm: null},
      price: {list: [], map: {}, prm: null},
      exchange: {list: [], map: {}, prm: null},
      holiday: {list: [], map: {}, prm: null},
      color: {list: [], map: {}, prm: null},
      designer_sku_pattern: {list: [], map: {}, prm: null},
      coupon: {list: [], map: {}, prm: null},
      prm: null,
      loaded: false,
      shopPreset: {list: [], prm: null},
      brandPreset: {list: [], prm: null},
      categoryPreset: {list: [], prm: null},
    },
    count: 0,
    private: false, // private mode
    alertTop: {show: false, text: '', variants: 'success', timeout: 0, lastHandler: 0},
    breakpoint: {
      isLteXs: false,
      isLteSm: false,
      isLteMd: false,
      isLteLg: false,
      isGteSm: false,
      isGteMd: false,
      isGteLg: false,
      isGteXl: false,
    }, // 화면 사이즈 인식
  },
  mutations: { // mutations를 통해 변경하는 이유는 state 변화를 추척하기 위해서 입니다. state 변화를 추척할 수 있기 때문에 디버깅이 편해집니다.
    login (state, user) {
      state.user = user;
      state.private = !!user.private;
      localStorage.setItem('user', JSON.stringify(user));
    },
    logout (state) {
      state.user = {};
      localStorage.removeItem('user');
    },
  },
  getters: {
    R: (state) =>
      /**
       * 권한 체크 함수
       * 다음 순서대로 권한을 체크한다.
       * 1. role 과 무관하게, blacklist 가 유저에 있고 요청과 일치한다면 block
       * 2. role 이 '' 라면, 로그인 여부만 확인 후 pass
       * 3. role 과 무관하게, whitelist 가 세션에 있고 요청과 일치한다면 pass
       * 4. role 체크
       *  - role 이 array 라면 그 중 하나라도 일치하면 통과시킨다.
       *  - role 이 string 이라면, 콤마로 구분된 role 들을 전부 가지고 있어야 한다.
       *  ex) checkAuth(['A', '!B']) -> A or not B
       *      checkAuth(['C,D', 'B']) -> C and D or B
       *
       * @param {string|string[]} role       필요 권한
       * @param {string|null} route          blacklist, whitelist 를 체크할 경로
       * @return {boolean}                   별도 봔한값 없이 next() 혹은 res.status() 호출
       */
      (role, route = null) => {
        // and or 조건으로 role 체크
        const roles = state.user.roles;
        if (!roles) return false;

        const whitelist = user.whitelist || [];
        const blacklist = user.blacklist || [];
        if (route) {
          // 블랙리스트가 최우선
          if (blacklist.length && blacklist.includes(route)) {
            return false;
          }
          // 권한 무관 화이트리스트 체크
          if (whitelist.length && whitelist.includes(route)) {
            return true;
          }
        }

        if (typeof role === 'string') {
          if (role.split(',').every(r => {
            if (r[0] === '!') {
              return !roles.includes(r.slice(1));
            }
            return roles.includes(r);
          })) return true;
        } else { // array 로 가정
          if (role.some(e => e.split(',').every(r => {
            if (r[0] === '!') {
              return !roles.includes(r.slice(1));
            }
            return roles.includes(r);
          }))) return true;
        }

        return false;
      },
    G: (state) => (group) => {
      return state.user.group && group.split(',').includes(state.user.group);
    },
  }
  // actions,
  // modules,
  // getters
})

export default store
