// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import './polyfill'
// import cssVars from 'css-vars-ponyfill'
import Vue from 'vue'
import VueGtag from "vue-gtag"
import BootstrapVue from 'bootstrap-vue'
import App from './App'
import store from './store'
import router from './router/index'
import './plugins/socket'
import Modal from './plugins/modal'
import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import {VueMasonryPlugin} from 'vue-masonry';
import * as moment from 'moment-timezone';
import Clipboard from 'v-clipboard'
import cTable from '@/views/TableBase.vue'
import shopPreset from '@/views/ShopPreset.vue'
import brandPreset from '@/views/BrandPreset.vue'
import categoryPreset from '@/views/CategoryPreset.vue'
import colorPreset from '@/views/ColorPreset.vue'
import dateInput from '@/views/modules/DateInput.vue'
import dateFromTo from '@/views/modules/DateFromTo.vue'
import * as utils from '@/shared/utils'
import * as api from '@/shared/api'
import FRONT_CONSTANTS from '@/shared/fields'
import * as C from 'balaan_constants';
import '@/shared/ht_helper';
import vSelect from 'vue-select'
import vMultiselect from 'vue-multiselect'
import 'vue-multiselect/dist/vue-multiselect.min.css'
import draggable from 'vuedraggable';
import 'prismjs/components/prism-core';
import 'prismjs/components/prism-clike';
import 'prismjs/components/prism-javascript';
import 'prismjs/themes/prism-tomorrow.css'; // import syntax highlighting styles
import 'chartjs-plugin-colorschemes';
import 'handsontable/dist/handsontable.full.css';
import {HotTable} from '@handsontable/vue';
import htb from '@/views/modules/HotTableBase.vue';
import {version} from '/package.json';

// Prefered: as a plugin (directive + filter) + custom placeholders support
import VueTheMask from 'vue-the-mask'
Vue.use(VueTheMask)
const F = C.fields;

const {state: S, getters: {R, G}} = store;

// meta 의 로딩을 시작한다.
if (S.user.approved) {
  S.m.prm = api.getAllMeta();
}

// components
Vue.component('v-select', vSelect)
Vue.component('v-multiselect', vMultiselect)
Vue.component('draggable', draggable)
Vue.component('c-table', cTable)
Vue.component('shop-preset', shopPreset)
Vue.component('brand-preset', brandPreset)
Vue.component('category-preset', categoryPreset)
Vue.component('color-preset', colorPreset)
Vue.component('date-input', dateInput)
Vue.component('date-from-to', dateFromTo)
Vue.component('hot-table', HotTable)
Vue.component('htb', htb)

import io from 'socket.io-client';
const socket = io.connect(api.FEED_HOST + '/', {
  transports: ['websocket'],
});

// global variables
Vue.prototype.$clientVer = version;
Vue.prototype.$socket = socket;
Vue.prototype.$utils = utils;
Vue.prototype.$api = api;
Vue.prototype.$moment = moment;
Vue.prototype.$S = S;
Vue.prototype.$R = R;
Vue.prototype.$G = G;
Vue.prototype.$C = C;
Vue.prototype.$F = FRONT_CONSTANTS;
Vue.prototype.$FN = F.getScopeName;
Vue.prototype.$FM = F.scopeMap;
Vue.prototype.$FL = F.FIELDS;
Vue.prototype.$alertTop = (text, {timeout = 1500, variants = 'success'} = {}) => {
  if (S.alertTop.show) {
    clearTimeout(S.alertTop.lastHandler);
  }
  S.alertTop.text = text;
  S.alertTop.variants = variants;
  S.alertTop.timeout = timeout;
  S.alertTop.show = true;
  S.alertTop.lastHandler = setTimeout(() => {
    S.alertTop.show = false;
  }, timeout);
};
window.proto = Vue.prototype;

import GlobalMixin from '@/plugins/global_mixin.js'

Vue.mixin(GlobalMixin);

// https://momentjscom.readthedocs.io/en/latest/moment/07-customization/07-relative-time/
// 사용은 this.$moment(someDt || '2021-01-01 00:00').fromNow()
moment.updateLocale('ko', {
  relativeTime: {
    future: "%s 이내",
    past: "%s 전",
    s: '1초',
    ss: '%d초',
    m: "1분",
    mm: "%d분",
    h: "1시간",
    hh: "%d시간",
    d: "1일",
    dd: "%d일",
    w: "1주일",
    ww: "%d주일",
    M: "1달",
    MM: "%d달",
    y: "1년",
    yy: "%d년"
  }
});

// todo
// cssVars()

// https://matteo-gabriele.gitbook.io/vue-gtag/v/master/
Vue.use(VueGtag, {
  config: {
    id: 'G-8HKYVDPKQD',
    params: {
      send_page_view: false,
      custom_map: {dimension1: 'group'}
    }
  },
  // enabled: false,
  // onReady () {
  //   console.log('// ready');
  // },
  // onBeforeTrack () {
  //   console.log('// before!');
  // },
  // onAfterTrack () {
  //   console.log('// after!');
  // }
})
Vue.use(BootstrapVue);
Vue.use(PerfectScrollbar);
Vue.use(VueMasonryPlugin);
Vue.use(Modal);
Vue.use(Clipboard);
Vue.directive('focus', {
  // 바인딩 된 엘리먼트가 DOM에 삽입되었을 때...
  inserted: function (el) {
    // 엘리먼트에 포커스를 줍니다
    el.focus()
  }
});
import VueCodemirror from 'vue-codemirror'

// import base style
import 'codemirror/lib/codemirror.css'
import 'codemirror/theme/dracula.css'
import 'codemirror/mode/markdown/markdown'
import 'codemirror/mode/javascript/javascript'
import 'codemirror/mode/xml/xml'
import 'codemirror/addon/merge/merge.js'
import 'codemirror/addon/merge/merge.css'
import DiffMatchPatch from 'diff-match-patch'
window.diff_match_patch = DiffMatchPatch
window.DIFF_DELETE = -1
window.DIFF_INSERT = 1
window.DIFF_EQUAL = 0

// you can set default global options and events when Vue.use
Vue.use(VueCodemirror, {
  options: {
    tabSize: 2,
    mode: 'text/javascript',
    theme: 'dracula',
    styleActiveLine: true,
    lineNumbers: true,
    lineWrapping: true,
    line: true,
  },
})

/* eslint-disable no-new */
export const vue = new Vue({
  el: '#app',
  router,
  store,
  sockets: {
    connect() {
      // console.log('main.js socket connected');
    },
    id(sid) {
      // 작업 완료보고 등을 개별로 받기 위해 id 를 저장해놨다가 요청시 보낸다.
      S.socketId = sid;
      // console.log('sid', sid);
    },
    async refreshMe(id) {
      // console.log('refreshMe', id);
      if (S.user && id === S.user.id) {
        let j = await api.getJson('/user/me');
        if (!j) return;
        Object.assign(S.user, j.user);
        localStorage.setItem('user', JSON.stringify(S.user));
      }
    },
    async refreshMeta(type) {
      console.log('refreshMeta', type);

      // TODO: 필요 부분만 로딩
      S.m.loaded = false;
      api.getAllMeta().then();
    }
    // connect: function () {
    //   console.log('socket connected')
    // },
    // customEmit: function (data) {
    //   console.log('this method was fired by the socket server. eg: io.emit("customEmit", data)')
    // }
  },
  template: '<App/>',
  components: {
    App
  }
})
