<template>
  <div class="">
    <div class="clearfix">
      <b-button @click="colors.splice(0, colors.length)" class="mr-1 mb-1" size="sm" variant="warning" v-b-tooltip.left="`${value && value.length || 0} 개 선택됨`">초기화</b-button>
    </div>
    <v-select :options="options.colors" :placeholder="options.colors.length ? `전체 ${options.colors.length} 개 색` : '로딩중...'" multiple v-model="colors">
      <template #option="{ hexcolor, label }">
        <div style="display: inline-block; width: 20px; height: 20px; vertical-align: middle" :style="{background: hexcolor}"></div> {{ label }}
      </template>
    </v-select>
  </div>
</template>

<script>

export default {
  name: "ColorPreset",
  model: {prop: 'value', event: 'change'},
  props: {
    value: Array
  },
  data() {
    return {
      options: {
        colors: [],
      }
    }
  },
  computed: {
    colors: {
      get() {
        return this.value;
      },
      set(v) {
        this.$emit('change', v);
      }
    },
  },
  async created() {
    let meta = await this.$api.getMeta('color');
    if (!meta) return;

    this.options.colors = meta.color.sort((a, b) => a.color.localeCompare(b.color));
    this.options.colors.forEach(s => {
      s.label = `${s.color} (${s.hexcolor})`;
      if (s.color === 'multi') {
        s.label = `${s.color} (Multi Color)`;
        s.hexcolor = `linear-gradient(135deg, red,orange,yellow,green,blue,indigo,violet)`;
      }
    });
  }
}
</script>
